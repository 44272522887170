import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function fetchEvaluationListingApi(data) {
  let apiUrl = `${apiBasePath}/assessmentEvaluation`;
  if (
    data?.assessmentId !== undefined &&
    data?.assessmentId !== null &&
    data?.assessmentId !== ""
  ) {
    apiUrl += `?assessmentId=${data?.assessmentId}`;
  }
  if (
    data?.offset !== undefined &&
    data?.offset !== null &&
    data?.offset !== ""
  ) {
    apiUrl += `&offset=${data?.offset}`;
  }
  if (
    data?.sortBy !== undefined &&
    data?.sortBy !== null &&
    data?.sortBy !== ""
  ) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (
    data?.sortOrder !== undefined &&
    data?.sortOrder !== null &&
    data?.sortOrder !== ""
  ) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  if (
    data?.textSearch !== undefined &&
    data?.textSearch !== null &&
    data?.textSearch !== ""
  ) {
    apiUrl += `&textSearch=${data?.textSearch}`;
  }
  if (data?.type !== undefined && data?.type !== null && data?.type !== "") {
    apiUrl += `&type=${data?.type}`;
  }
  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function createEvaluationApi(id) {
  const options = {
    method: "POST",
    url: `${apiBasePath}/assessmentEvaluation?assessmentId=${id}`,
    data: id,
  };
  return ApiService(options);
}
export function updateEvaluationApi(data) {
  let apiUrl = `${apiBasePath}/assessmentEvaluation`;

  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options, true, true);
}
export function sendEvaluationReminderApi({ assessmentId }) {
  let apiUrl = `${apiBasePath}/assessmentEvaluation/${assessmentId}`;

  const options = {
    method: "POST",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getAssessmentByIdOnEvaluationApi(id) {
  const options = {
    method: "GET",
    url: `${apiBasePath}/assessment/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function getBootcampByIdApi({ bootcampId }) {
  let apiUrl = `${apiBasePath}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getOptionsForEditEvaluationInterviewerApi({ assessmentId }) {
  let apiUrl = `${apiBasePath}/utilities/optionsForEditEvaluationInterviewer`;
  if (assessmentId) {
    apiUrl += `?assessmentId=${assessmentId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function updateAttendeeApi(data) {
  let apiUrl = `${apiBasePath}/assessmentEvaluation`;

  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

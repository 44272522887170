import React, { useEffect, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDetails } from "../../../store/SessionView/detailsTab";
import dayjs from "dayjs";
import humanizeDuration from "humanize-duration";
import DetailsTabAgenda from "./DetailsTabAgenda";
import DetailsTabExercise from "./DetailsTabExercise";
import DetailsTabResources from "./DetailsTabResources";
import Loader from "../../../components/Loader/Loader";
import NoDataAvailable from "../../../components/NoDataAvailable/NoDataAvailable";
import BootstrapTooltip from "../../../components/BootstrapTooltip/BootstrapTooltip";

const DetailsTab = ({ matchedBootcamp }) => {
  const security = useSelector((state) => state?.security);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const loader = Details?.loading;
  const DetailsData = Details?.details?.data;
  const resources = Details?.details?.data?.resources?.results;

  const startDate = dayjs(DetailsData?.startDate);
  const endDate = dayjs(DetailsData?.endDate);
  const duration = humanizeDuration(endDate?.diff(startDate));

  const handleExercise = (e) => {
    e.stopPropagation();
    navigate(window.location.pathname + `/questions`);
  };

  ////////////////// Incorrect URL Check //////////////////
  const isFirstRun = useRef(true);
  const moduleIdToBeMatched = params?.moduleId && DetailsData?.module?.id;

  useEffect(() => {
    if (!isFirstRun.current) {
      if (
        (moduleIdToBeMatched && moduleIdToBeMatched !== +params?.moduleId) ||
        (matchedBootcamp && matchedBootcamp?.id !== +params?.bootcampId)
      ) {
        navigate("/error", { replace: true });
      }
    }
    isFirstRun.current = false;
  }, [moduleIdToBeMatched, matchedBootcamp]);

  useEffect(() => {
    const fetchData = async () => {
      const apiValue = await dispatch(
        getDetails({
          bootcampId: params?.bootcampId,
          moduleId: params?.moduleId,
          topicId: params?.topicId,
          sessionId: params?.sessionId,
        })
      );
    };
    if (
      isNaN(+params?.sessionId) ||
      isNaN(+params?.moduleId) ||
      isNaN(+params?.bootcampId)
    ) {
      navigate("/error", { replace: true });
      return;
    } else if (security?.loggedInUser?.id) {
      fetchData();
    }
  }, [dispatch, params?.sessionId]);

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      {!DetailsData ? (
        <Grid container className="no-data-container">
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="No Data Available"
          />
        </Grid>
      ) : (
        <Grid container>
          <Grid item md={12} mb={2.5}>
            <Grid container spacing={0} gap={0}>
              <Grid className="grid-flex-boxes" item md={6}>
                <Typography variant="font_12_bold" className="text-grey-color">
                  Module
                </Typography>

                <Typography variant="font_14" color="secondary">
                  <Link
                    to={`/bootcamp/${params?.bootcampId}/module/${DetailsData?.module?.id}`}
                  >
                    <BootstrapTooltip title="View Module">
                      {DetailsData?.module?.name}
                    </BootstrapTooltip>
                  </Link>
                </Typography>
              </Grid>
              <Grid
                className="grid-flex-boxes custom-right-border "
                item
                md={6}
              >
                <Typography variant="font_12_bold" className="text-grey-color">
                  Topic
                </Typography>
                <Typography variant="font_14" color="secondary">
                  {DetailsData?.topic?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={0} gap={0}>
              <Grid
                className="grid-flex-boxes custom-bottom-border"
                item
                md={6}
              >
                <Typography variant="font_12_bold" className="text-grey-color">
                  Start Date/Time
                </Typography>
                <Typography variant="font_14" color="text.primary">
                  {dayjs(DetailsData?.startDate)?.format("DD/MM/YYYY hh:mm A")}
                </Typography>
              </Grid>
              <Grid
                className="grid-flex-boxes custom-bottom-border custom-right-border"
                item
                md={6}
              >
                <Typography variant="font_12_bold" className="text-grey-color">
                  Duration
                </Typography>
                <Typography variant="font_14" color="text.primary">
                  {duration}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <DetailsTabAgenda agenda={DetailsData?.agenda} />
          <DetailsTabExercise
            exercises={DetailsData?.exerciseQuestions}
            handleExercise={handleExercise}
          />
          <DetailsTabResources resources={resources} />
        </Grid>
      )}
    </>
  );
};

export default DetailsTab;

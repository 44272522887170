import dayjs from "dayjs";
import config from "../config/config";
import { attendance, authBasePath } from "./../constants/constants";
import { createFilterOptions } from "@mui/material";
import validator from "validator";

const { parse, format } = require("date-fns");
var minMax = require("dayjs/plugin/minMax");
dayjs.extend(minMax);
const tz = "Asia/Kolkata";

//const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const font_21 = 1.313;
export const font_20 = 1.25;
export const font_17 = 1.063;
export const font_16 = 1;
export const font_15 = 0.938;
export const font_14 = 0.875;
export const font_13 = 0.813;
export const font_13_icon = 0.836;
export const font_12 = 0.75;
export const font_11 = 0.688;
export const font_10 = 0.625;
export const font_9 = 0.563;

export const getYear = () => {
  return new Date().getFullYear();
};

export function getAbbreviation(inputString) {
  if (typeof inputString !== "string") {
    return "";
  }
  const words = inputString.replace(/[^a-zA-Z ]/g, "").split(" ");
  const abbreviation = words.map((word) => word.charAt(0)).join("");
  return abbreviation.toUpperCase();
}

export const getDate = (date) => {
  return dayjs(date).isValid() ? dayjs(date).format("DD/MM/YYYY") : null;
};

export const getDateTime = (date) => {
  return dayjs(date).isValid()
    ? dayjs(date).format("DD/MM/YYYY hh:mm A")
    : null;
};

export const getLoginEndpoint = () => {
  if (config.enableJugaadLogin) {
    return `${config.backendBaseUrl}${authBasePath}/jugaadLogin?email=vishnu.aggarwal@tothenew.com`;
  } else {
    return `${config.backendBaseUrl}${authBasePath}/login`;
  }
};

export const getNewersWorldProfileUrl = (employeeCode) => {
  return `https://newersworld.tothenew.com/#/unifiedEmployeeView/${employeeCode}`;
};

export const getImpersonateUserEndpoint = (email) => {
  return `${config.backendBaseUrl}${authBasePath}/impersonateUser?email=${email}`;
};

export const ROLE_TYPES = {
  ADMIN: {
    role: "ADMIN",
    check: (userRoles) => userRoles?.some((role) => role === "ADMIN") || false,
  },
  HR: {
    role: "HR",
    check: (userRoles) => userRoles?.some((role) => role === "HR") || false,
  },
  COMPETENCY_SPOC: {
    role: "COMPETENCY_SPOC",
    check: (userRoles) =>
      userRoles?.some((role) => role === "COMPETENCY_SPOC") || false,
  },
  COMPETENCY_LEAD: {
    role: "COMPETENCY_LEAD",
    check: (userRoles) =>
      userRoles?.some((role) => role === "COMPETENCY_LEAD") || false,
  },
  USER: {
    role: "USER",
    check: (userRoles) => userRoles?.some((role) => role === "USER") || false,
  },
  isAdmin: (userRoles) => ROLE_TYPES?.ADMIN?.check(userRoles) || false,
  isHr: (userRoles) => ROLE_TYPES?.HR?.check(userRoles) || false,
  isCompetencySpoc: (userRoles) =>
    ROLE_TYPES?.COMPETENCY_SPOC?.check(userRoles) || false,
  isCompetencyLead: (userRoles) =>
    ROLE_TYPES?.COMPETENCY_LEAD?.check(userRoles) || false,
  isUser: (userRoles) => ROLE_TYPES?.USER?.check(userRoles) || false,

  getLabel: (role) => {
    if (role === ROLE_TYPES?.USER?.role) return "User";
    else if (role === ROLE_TYPES?.ADMIN?.role) return "Admin";
    else if (role === ROLE_TYPES?.COMPETENCY_SPOC?.role)
      return "Competency SPOC";
    else if (role === ROLE_TYPES?.COMPETENCY_LEAD?.role)
      return "Competency Lead";
    else if (role === ROLE_TYPES?.HR?.role) return "HR";
    else return "";
  },

  isOnlyRole: (userRoles, roleToCheck) => {
    const rolesWithoutUser = userRoles.filter((role) => role !== "USER");
    const roleExists = rolesWithoutUser.some((role) => role === roleToCheck);
    return roleExists && rolesWithoutUser.length === 1;
  },
};

export const hasAnyPermission = (permission) => {
  for (let key in permission) {
    if (permission[key] === true) {
      return true;
    }
  }
  return false;
};

export const permissionAllowedTo = (permission) => {
  const truePermissions = [];
  for (let key in permission) {
    if (permission[key] === true) {
      truePermissions.push(key);
    }
  }
  return truePermissions;
};

export const isOnlyTrueForPermissionKey = (permission, key) => {
  if (!permission || !(key in permission)) {
    return false;
  }

  return (
    Object.values(permission).filter((value) => value).length === 1 &&
    permission[key]
  );
};

const itemsToCheck = [
  "spoc",
  "sessionPresenter",
  "mentor",
  "attendee",
  "exerciseReviewer",
];

export const isItemIncludedExcluding = (excludedItems, finalArrayToCheck) => {
  return itemsToCheck.some(
    (item) => item !== excludedItems && finalArrayToCheck.includes(item)
  );
};

export const parseDate = (date) => parse(date, DATE_TIME_FORMAT, null);

export const formatDate = (date) => format(date, DATE_TIME_FORMAT);

export const encode = (text) => encodeURIComponent(text);

export const todayDate = () => {
  const today = new Date();
  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  todayDate.setHours(0);
  todayDate.setMinutes(0);
  todayDate.setSeconds(0);
  todayDate.setMilliseconds(0);

  dayjs().startOf("day");
  return todayDate;
};

export const regex = /^[0-9]{1,5}$/;

export const calculateFilterCount = (filterObject) => {
  const count = Object.values(filterObject).filter((value) => {
    return (
      value !== "" &&
      value !== undefined &&
      value !== null &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === "boolean" && value === false)
    );
  }).length;

  return count;
};

export const checkSpecialChar = (value) => {
  const charRegex = /[`!@#$%^&*()+=[\]{};':"\\,.<>\/?~]/;
  if (charRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isValidArray = (arr) => {
  return (
    arr !== undefined && arr !== null && arr.length > 0 && Array.isArray(arr)
  );
};

export const isValidString = (str) => {
  if (str === undefined || str === null || !(typeof str === "string")) {
    return false;
  }
  str = str.trim();
  return str.length > 0;
};

export function checkDateTimeStatus(startDate, endDate) {
  const today = dayjs();
  if (dayjs(startDate).isBefore(today) && dayjs(endDate).isAfter(today)) {
    return "ongoing";
  } else if (
    dayjs(startDate).isBefore(today) &&
    dayjs(endDate).isBefore(today)
  ) {
    return "past";
  } else if (dayjs(startDate).isAfter(today)) {
    return "future";
  }
}

export const getLatestDate = (date) => {
  const today = dayjs();
  const date2 = dayjs(date);
  return date2.isBefore(today, "date") ? today : date2;
};

export const getMaxDate = (dates) => {
  if (dates?.length === 0) {
    return null;
  }
  return dayjs.max(
    [...dates]?.map((it) => dayjs(it))?.filter((it) => it.isValid())
  );
};

export const getMinDate = (dates) => {
  if (dates?.length === 0) {
    return null;
  }
  return dayjs.min(
    [...dates]?.map((it) => dayjs(it))?.filter((it) => it.isValid())
  );
};

export const getBootcampStatus = (value) => {
  switch (value) {
    case "ACTIVE":
      return { name: "ACTIVE", color: "#3087E9", text: "#FFF" };
    case "SCHEDULED":
      return { name: "SCHEDULED", color: "#FFAF00", text: "#FFF" };
    case "COMPLETED":
      return { name: "COMPLETED", color: "#05B14E", text: "#FFF" };
    default:
      return { name: "Unknown", color: "red", text: "#FFF" };
  }
};

export const filterRolesDropDown = (value, allRoles) => {
  const checkCompetency = (value) => {
    let competency;
    switch (value?.name) {
      case "HR SPOC":
      case "HR Operations":
      case "HR Business Partner":
        return (competency = "HR");
      default:
        return (competency = "User");
    }
  };
  const filterBy = checkCompetency(value);
  const listRoles = allRoles;
  let filteredRoles;
  if (filterBy === "HR") {
    filteredRoles = listRoles?.filter(
      (role) => role.key === "ADMIN" || role.key === "HR" || role.key === "USER"
    );
  } else {
    filteredRoles = listRoles?.filter(
      (role) =>
        role.key !== "HR" &&
        role.key !== "COMPETENCY_LEAD" &&
        role.key !== "USER"
    );
  }
  return filteredRoles;
};

export const shouldDisableWeekends = (date) => {
  const day = date.day();
  // Disable Saturday (6) and Sunday (0)
  return day === 0 || day === 6;
};

export const SingleWordCount = (str) => {
  return str.split(" ").length;
};
export const exerciseStatusChip = (value) => {
  switch (value) {
    case "PENDING":
      return { name: "Pending", theme: "PENDING" };
    case "IN_REVIEW":
      return { name: "In Review", theme: "IN_REVIEW" };
    case "REVIEWED":
      return { name: "Reviewed", theme: "REVIEWED" };
    case "REOPENED":
      return { name: "Reopened", theme: "REOPENED" };
    default:
      return { name: "Unknown", theme: "RED" };
  }
};

export const attendanceStatusChip = (value) => {
  switch (value) {
    case "NOT_MARKED":
      return { name: "Not Marked", theme: "BLUE-TEXT" };
    case "PRESENT":
      return { name: "Present", theme: "GREEN-TEXT" };
    case "ABSENT":
      return { name: "Absent", theme: "RED-TEXT" };
    default:
      return { name: "Unknown", theme: "RED" };
  }
};

export const hasDetailsChanged = (initialDetails, currentDetails) => {
  return JSON.stringify(initialDetails) !== JSON.stringify(currentDetails);
};

export const sortByFullName = (data) => {
  const newData = [...data];
  return newData?.sort((a, b) => {
    const getNameComponents = (fullName) => {
      let [firstName, middleName, lastName] = fullName.split(" ");
      lastName = lastName || "";
      middleName = middleName || "";
      return { firstName, middleName, lastName };
    };

    const nameA = getNameComponents(a.fullName);
    const nameB = getNameComponents(b.fullName);

    if (nameA.firstName < nameB.firstName) return -1;
    if (nameA.firstName > nameB.firstName) return 1;

    if (nameA.middleName < nameB.middleName) return -1;
    if (nameA.middleName > nameB.middleName) return 1;

    if (nameA.lastName < nameB.lastName) return -1;
    if (nameA.lastName > nameB.lastName) return 1;

    return 0;
  });
};

export const toWorkingDay = (date, holidayList) => {
  let finaldate = dayjs(date).tz(tz);
  // Adjust for holidays and weekends in a loop
  while (
    isHoliday(finaldate, holidayList) ||
    finaldate.day() === 0 ||
    finaldate.day() === 6
  ) {
    finaldate = finaldate.add(1, "day");
  }
  return finaldate;
};

// Function to check if the date falls on a holiday
export const isHoliday = (date, holidayList) => {
  return holidayList?.some((holiday) => {
    const start = dayjs(holiday?.start)?.tz(tz)?.startOf("day");
    const end = dayjs(holiday?.end)?.tz(tz)?.endOf("day");
    return date.isBetween(start, end, null, "[]"); // '[]' to include the start and end dates
  });
};

export const addWorkingDays = (date, daysToAdd, holidayList) => {
  let finalDate = dayjs(date).tz(tz);
  let addedDays = 1;

  while (addedDays < daysToAdd) {
    finalDate = finalDate.add(1, "day");

    // Check if the new date is a weekend or a holiday
    if (isWorkDay(finalDate, holidayList)) {
      addedDays++;
    }
  }

  return finalDate;
};

export const subtractWorkingDays = (date, daysToAdd, holidayList) => {
  let finalDate = dayjs(date).tz(tz);
  let addedDays = 0;

  while (addedDays > daysToAdd) {
    finalDate = finalDate.subtract(1, "day");

    // Check if the new date is a weekend or a holiday
    if (isWorkDay(finalDate, holidayList)) {
      addedDays--;
    }
  }

  return finalDate;
};

export const addOrSubtractWorkingDays = (date, daysToAdd, holidayList) => {
  let finalDate = dayjs(date);
  if (daysToAdd > 0) {
    addWorkingDays(date, daysToAdd, holidayList);
  } else {
    subtractWorkingDays(date, daysToAdd, holidayList);
  }
  return finalDate;
};

export const toStartDate = (date) => {
  return dayjs(date).tz(tz).set("hour", 0).set("minute", 0).set("second", 0);
};

export const toEndDate = (date) => {
  return dayjs(date).tz(tz).set("hour", 23).set("minute", 59).set("second", 59);
};

export const isWorkDay = (date, holidayList) => {
  const date1 = dayjs(date);
  return (
    date1.day() !== 0 && date1.day() !== 6 && !isHoliday(date1, holidayList)
  );
};

export const noOfWorkingDaysChanged = (date1, date2, holidayList) => {
  let currentDate = dayjs(date1).tz(tz);
  const endDay = dayjs(date2).tz(tz);
  let workingDaysCount = 0;

  if (currentDate.isBefore(endDay, "date")) {
    // Count forward
    while (!currentDate.isAfter(endDay, "date")) {
      if (isWorkDay(currentDate, holidayList)) {
        workingDaysCount++;
      }
      currentDate = currentDate.add(1, "day");
    }
  } else {
    // Count backward
    while (!currentDate.isBefore(endDay, "date")) {
      if (isWorkDay(currentDate, holidayList)) {
        workingDaysCount--;
      }
      currentDate = currentDate.subtract(1, "day");
    }
  }

  return workingDaysCount;
};
export const countWorkingDays = (startDate, endDate, holidayList) => {
  let currentDate = dayjs(startDate).tz(tz);
  const endDay = dayjs(endDate).tz(tz);
  let workingDaysCount = 0;

  while (currentDate.isBefore(endDay) || currentDate.isSame(endDay, "day")) {
    // Check if the current date is a weekend or a holiday
    if (isWorkDay(currentDate, holidayList)) {
      workingDaysCount++;
    }
    currentDate = currentDate.add(1, "day");
  }

  return workingDaysCount;
};

export const filterOptionsWithSelectAllDesignations = (
  options,
  params,
  resultsLength,
  optionsWithAllDesignation
) => {
  const filter = createFilterOptions();
  const filtered = filter(options, params);

  if (resultsLength === 0) {
    return [{ name: "No options", all: false }];
  }

  // Create the "All" option
  const allOption = {
    name: "All",
    id: Date.now(),
    users: {
      results: optionsWithAllDesignation,
    },
  };

  // Check if the search term matches "All" or is empty
  const matchesAllOption = "all".includes(params?.inputValue?.toLowerCase());

  // If no search term or the search term matches "All", include it
  if (!params?.inputValue || matchesAllOption) {
    return [allOption, ...filtered];
  }

  // Otherwise, return the filtered options
  return filtered;
};

/////////////////////////////////////////////////////
// To check changedValues in dropdown//
export const getChangedIds = (currentValues, detailsToCheck) => {
  const previousIds = detailsToCheck.map((e) => e?.id);
  const currentIds = currentValues.map((e) => e?.id);
  const changedIds = previousIds
    .filter((element) => !currentIds.includes(element))
    .concat(currentIds.filter((element) => !previousIds.includes(element)));
  return changedIds;
};

// To check if it contains the disabledIds in dropdown//
export const allValuesDisabled = (details, disabledIds) => {
  // Check if lengths are the same
  if (details?.length !== disabledIds?.length) {
    return false;
  }

  // Check if each id in disabledIds is in details
  const itemIdsToBeChecked = details?.map((item) => item?.id);
  const allIdsExist = disabledIds?.every((id) =>
    itemIdsToBeChecked?.includes(id)
  );

  return allIdsExist;
};

/////////////////////////////////////////////////////
// To check URL Format //
const invalidWWWPattern = /^www\.[A-Za-z]+$/;
export const gitUrlPattern = /^(git@)[\w.-]+(:|\/)[\w./-]+\.git$/;

export const isValidUrlFormat = (url) => {
  const options = {
    require_tld: true, // Require a valid top-level domain (TLD)
    require_protocol: true, // Do not require a protocol (http, https)
    allow_protocol_relative_urls: false, // Do not allow protocol-relative URLs (e.g., //example.com)
  };
  if (invalidWWWPattern.test(url)) {
    return false;
  }
  // Check if it's a valid HTTP/HTTPS URL
  if (validator?.isURL(url, options)) {
    return true;
  }
  // Check if it's a valid Git SSH URL
  return gitUrlPattern?.test(url);
};

export const getStatus = (attendeeStatuses, statusToCheck) => {
  const statusObj = attendeeStatuses?.find(
    (statusObj) => statusObj?.key === statusToCheck
  );
  return statusObj ? statusObj?.value : null;
};

export const addOneDayExcludingWeekend = (date) => {
  let newDate = dayjs(date).add(1, "day"); // Add one day and set the time to 12 AM (start of the day)

  // Check if the new date falls on a weekend
  if (newDate?.day() === 6) {
    // If it's Saturday, add 2 more days (to make it Monday)
    newDate = newDate?.add(2, "day");
  } else if (newDate?.day() === 0) {
    // If it's Sunday, add 1 more day (to make it Monday)
    newDate = newDate?.add(1, "day");
  }
  return newDate;
};

export const eventDateRange = (date, module) => {
  return [module]?.some(
    (it) =>
      !dayjs(date).tz().isBefore(dayjs(it?.startDate).tz().startOf("day")) &&
      !dayjs(date).tz().isAfter(dayjs(it?.endDate).tz().endOf("day"))
  );
};

export const calculateAssessmentCounts = (type, assessmentQuestions) => {
  const result = {
    EASY: 0,
    MEDIUM: 0,
    HARD: 0,
  };

  if (type === "MCQ") {
    result.EASY =
      assessmentQuestions?.EASY?.MULTI_SELECT +
      assessmentQuestions?.EASY?.SINGLE_SELECT;
    result.MEDIUM =
      assessmentQuestions?.MEDIUM?.MULTI_SELECT +
      assessmentQuestions?.MEDIUM?.SINGLE_SELECT;
    result.HARD =
      assessmentQuestions?.HARD?.MULTI_SELECT +
      assessmentQuestions?.HARD?.SINGLE_SELECT;
  } else {
    result.EASY = assessmentQuestions?.EASY?.TEXT;
    result.MEDIUM = assessmentQuestions?.MEDIUM?.TEXT;
    result.HARD = assessmentQuestions?.HARD?.TEXT;
  }

  return result;
};

export const maxAssessmentCounts = (type, assessmentQuestions, topicId) => {
  const result = {
    EASY: 0,
    MEDIUM: 0,
    HARD: 0,
  };

  const topicQuestions = assessmentQuestions?.[topicId];

  if (!topicQuestions) {
    return result;
  }

  if (type === "MCQ") {
    result.EASY =
      topicQuestions?.EASY?.MULTI_SELECT + topicQuestions?.EASY?.SINGLE_SELECT;
    result.MEDIUM =
      topicQuestions?.MEDIUM?.MULTI_SELECT +
      topicQuestions?.MEDIUM?.SINGLE_SELECT;
    result.HARD =
      topicQuestions?.HARD?.MULTI_SELECT + topicQuestions?.HARD?.SINGLE_SELECT;
  } else {
    result.EASY = topicQuestions?.EASY?.TEXT;
    result.MEDIUM = topicQuestions?.MEDIUM?.TEXT;
    result.HARD = topicQuestions?.HARD?.TEXT;
  }

  return result;
};

export const createFor = [
  {
    key: "Bootcamp",
    value: "Bootcamp",
  },
  {
    key: "MODULE",
    value: "Module",
  },
];

export const evaluationStatusChip = (value) => {
  switch (value) {
    case "ACTIVE":
      return { name: "Active", theme: "BLUE" };
    case "CANCELLED":
      return { name: "Cancelled", theme: "RED" };
    case "COMPLETED":
      return { name: "Completed", theme: "GREEN" };
    case "SCHEDULED":
      return { name: "Scheduled", theme: "YELLOW" };
    default:
      return { name: "Unknown", theme: "RED" };
  }
};

export const selectionOptions = [
  {
    key: "SELECT_ALL",
    value: "Select All",
  },
  {
    key: "NOT_MARKED",
    value: "Not Marked",
  },
];

/////////////////////////////////////////////////////
// To use naviagte //
let navigate;

export const setNavigate = (nav) => {
  navigate = nav;
};

export const getNavigate = () => {
  return navigate;
};

// To check if in IST //
export const isInIST = () => {
  const systemTimeZone = dayjs().tz(dayjs.tz.guess()).format("ZZ"); // Get current time zone offset in UTC (e.g., "+0530")
  const istOffset = "+0530"; // IST offset

  return systemTimeZone === istOffset; // Compare with IST offset
};

// To find the max question count //
export const extractActiveQuestionCounts = (details) => {
  return details?.topicQuestions?.reduce((acc, topic) => {
    acc[topic?.topicId] = topic?.activeAssessmentQuestionCount;
    return acc;
  }, {});
};

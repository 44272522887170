import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getSessionListApi(data) {
  let apiUrl = `${apiBasePath}/session?includePresenters=true`;

  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }
  if (
    data?.moduleId !== undefined &&
    data?.moduleId !== null &&
    data?.moduleId !== ""
  ) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }
  if (
    data?.topicId !== undefined &&
    data?.topicId !== null &&
    data?.topicId !== ""
  ) {
    apiUrl += `&topicId=${data?.topicId}`;
  }

  if (data?.offset !== undefined && data?.offset !== "") {
    apiUrl += `&offset=${data?.offset * 20}`;
  }
  if (
    data?.endDate !== undefined &&
    data?.endDate !== null &&
    data?.endDate !== ""
  ) {
    apiUrl += `&endDate=${data?.endDate}`;
  }
  if (
    data?.startDate !== undefined &&
    data?.startDate !== null &&
    data?.startDate !== ""
  ) {
    apiUrl += `&startDate=${data?.startDate}`;
  }
  if (data?.name !== undefined && data?.name !== null && data?.name !== "") {
    apiUrl += `&name=${data?.name}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getAllModulesApi() {
  let apiUrl = `${apiBasePath}/modules?paginated=false&statuses=ACTIVE,SCHEDULED`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getAllTopicApi() {
  let apiUrl = `${apiBasePath}/topics?paginated=false`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getAllExerciseOptionsApi(data) {
  let apiUrl = `${apiBasePath}/exerciseQuestions?id=${data?.sessionId}&type=SESSION`;

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function addSessionApi(data) {
  const options = {
    method: "POST",
    url: `${apiBasePath}/session`,
    data: data,
  };
  return ApiService(options);
}

export function editSessionApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiBasePath}/session`,
    data: data,
  };
  return ApiService(options);
}

export function deleteSessionApi(data) {
  let apiUrl = `${apiBasePath}/session/${data}`;

  const options = {
    method: "DELETE",
    url: apiUrl,
  };
  return ApiService(options);
}

export function addResourceApi(data) {
  const options = {
    method: "POST",
    url: `${apiBasePath}/resources`,
    data: data,
  };
  return ApiService(options, true, true);
}

export function getResourceBySessionIdApi(id) {
  let apiUrl = `${apiBasePath}/resources?type=SESSION&id=${id}`;
  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getSessionFilterTopicApi(data) {
  const { topicId } = data;
  let apiUrl = `${apiBasePath}/utilities/filtersForTopicSessionListing?topicId=${topicId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function editResourceApi(data) {
  const options = {
    method: "PUT",
    url: `${apiBasePath}/resources`,
    data: data,
  };
  return ApiService(options, true, true);
}

import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getPerformanceDataApi(data) {
  let apiUrl = `${apiBasePath}/performances?paginated=false`;

  if (data?.asAttendee !== undefined) {
    apiUrl += `&asAttendee=${data?.asAttendee}`;
  }
  if (data?.bootcampId) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }
  if (data?.max) {
    apiUrl += `&max=${data?.max}`;
  }
  if (data?.offset) {
    apiUrl += `&offset=${data?.offset}`;
  }
  if (data?.sortBy) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import { useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import {
  filterOptionsWithSelectAllDesignations,
  sortByFullName,
} from "../../utils/utils";

const compareFormFields = (initial, current) => {
  const fieldsToCheck = [
    "interviewerCompetencyId",
    "interviewerDesignationId",
    "leadInterviewerId",
    "coInterviewerCompetencyId",
    "coInterviewerDesignationId",
    "coInterviewerId",
  ];

  return fieldsToCheck.some((field) => {
    if (field.includes("Id")) {
      return initial[field]?.id !== current[field]?.id;
    }
    return initial[field] !== current[field];
  });
};

const EditEvaluationInterviewer = ({
  open,
  title,
  handleClose,
  handleSubmit,
  editEvaluationInterviewerDetails,
  handleEditTraineeChange,
  errors,
  competencyList,
  userLoading,
  isEditTrainee,
}) => {
  /////////////////////// To disable update button if data isn't changed ////////////////////////
  const [initialEditTraineeDetails, setInitialEditTraineeDetails] = useState(
    editEvaluationInterviewerDetails
  );
  const [hasSetInitialDetails, setHasSetInitialDetails] = useState(false);

  useEffect(() => {
    if (
      !hasSetInitialDetails &&
      editEvaluationInterviewerDetails?.interviewerCompetencyId &&
      editEvaluationInterviewerDetails?.coInterviewerCompetencyId
    ) {
      setInitialEditTraineeDetails(editEvaluationInterviewerDetails);
      setHasSetInitialDetails(true);
    }
  }, [
    editEvaluationInterviewerDetails?.interviewerCompetencyId,
    editEvaluationInterviewerDetails?.coInterviewerCompetencyId,
    hasSetInitialDetails,
  ]);

  const editTraineeDetailsChanged = compareFormFields(
    initialEditTraineeDetails,
    editEvaluationInterviewerDetails
  );

  const handleCloseEditTrainee = () => {
    setHasSetInitialDetails(false);
    handleClose();
  };
  const handleSubmitEditTrainee = () => {
    setHasSetInitialDetails(false);
    handleSubmit();
  };

  //////////////////////////////////////////////

  const params = useParams();
  const interviewerWithAllDesignation = [].concat(
    ...(editEvaluationInterviewerDetails?.interviewerCompetencyId?.designations?.results
      ?.filter((it) => !!it)
      ?.map((it) => it?.users?.results)
      ?.filter((it) => !!it) || [])
  );
  const filterInterviewerOptions =
    editEvaluationInterviewerDetails?.interviewerDesignationId
      ? editEvaluationInterviewerDetails?.interviewerDesignationId?.users
          ?.results
      : interviewerWithAllDesignation;

  const interviewerOptions = sortByFullName(filterInterviewerOptions);

  const coInterviewerWithAllDesignation = [].concat(
    ...(editEvaluationInterviewerDetails?.coInterviewerCompetencyId?.designations?.results
      ?.filter((it) => !!it)
      ?.map((it) => it?.users?.results)
      ?.filter((it) => !!it) || [])
  );
  const filterCoInterviewerOptions =
    editEvaluationInterviewerDetails?.coInterviewerDesignationId
      ? editEvaluationInterviewerDetails?.coInterviewerDesignationId?.users
          ?.results
      : coInterviewerWithAllDesignation;

  const coInterviewerOptions = sortByFullName(filterCoInterviewerOptions);

  return (
    <>
      {userLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleCloseEditTrainee}
        className="modal-drawer-container"
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            width: "27.125rem",
            zIndex: 1300,
            borderRadius: "1rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="dialog-title">{title}</DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            <Box
              component="fieldset"
              sx={{
                padding: "0 0.5rem 0.5rem",
                marginBottom: "0.5rem",
                border: "1px solid #c4c4c4",
                borderRadius: "0.4rem",
              }}
            >
              <legend>
                <Typography sx={{ padding: "0 0.2rem" }}>
                  Interviewer
                </Typography>
              </legend>

              <SimpleAutoComplete
                label="Competency"
                placeholder="Select Competency"
                options={competencyList}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("interviewerCompetencyId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                value={
                  editEvaluationInterviewerDetails?.interviewerCompetencyId
                }
                disableClearable
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id ===
                        editEvaluationInterviewerDetails
                          ?.interviewerCompetencyId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              <SimpleAutoComplete
                label="Designation"
                placeholder="Select Designation"
                options={
                  editEvaluationInterviewerDetails?.interviewerCompetencyId
                    ?.designations?.results
                }
                onChange={(event, newValue) =>
                  handleEditTraineeChange("interviewerDesignationId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                filterOptions={(options, params) =>
                  filterOptionsWithSelectAllDesignations(
                    options,
                    params,
                    editEvaluationInterviewerDetails?.interviewerCompetencyId
                      ?.designations?.results?.length,
                    interviewerWithAllDesignation
                  )
                }
                value={
                  editEvaluationInterviewerDetails?.interviewerDesignationId
                    ? editEvaluationInterviewerDetails?.interviewerDesignationId
                    : { name: "All", all: true }
                }
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        (option?.name === "All" &&
                          option?.name === value?.inputValue) ||
                        option?.id ===
                          editEvaluationInterviewerDetails
                            ?.interviewerDesignationId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
                disableClearable
              />
              <SimpleAutoComplete
                label="Interviewer"
                placeholder="Select Interviewer"
                options={interviewerOptions?.filter(
                  (user) =>
                    user?.id !==
                    editEvaluationInterviewerDetails?.coInterviewerId?.id
                )}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) =>
                    `${option.fullName} ${option.email}`
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  );
                }}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("leadInterviewerId", newValue)
                }
                getOptionLabel={(option) => option?.fullName}
                value={editEvaluationInterviewerDetails?.leadInterviewerId}
                error={!!errors?.leadInterviewerId}
                helperText={errors?.leadInterviewerId}
                required={!params?.moduleId}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id ===
                        editEvaluationInterviewerDetails?.leadInterviewerId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.fullName}`}</Box>
                        <Typography variant="caption" className="filter-title ">
                          {`${option?.email}`}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
                disableClearable={!params?.moduleId}
              />
            </Box>
          </Box>
          <Box
            component="fieldset"
            sx={{
              padding: "0 0.5rem 0.5rem",
              marginBottom: "0.5rem",
              border: "1px solid #c4c4c4",
              borderRadius: "0.4rem",
            }}
          >
            <legend>
              <Typography sx={{ padding: "0 0.2rem" }}>
                Co-Interviewer
              </Typography>
            </legend>
            <Box mb={1} className="width-100">
              <SimpleAutoComplete
                label="Competency"
                placeholder="Select Competency"
                options={competencyList}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("coInterviewerCompetencyId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                value={
                  editEvaluationInterviewerDetails?.coInterviewerCompetencyId
                }
                disableClearable={isEditTrainee ? true : false}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id ===
                        editEvaluationInterviewerDetails
                          ?.coInterviewerCompetencyId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              <SimpleAutoComplete
                label="Designation"
                placeholder="Select Designation"
                options={
                  editEvaluationInterviewerDetails?.coInterviewerCompetencyId
                    ?.designations?.results
                }
                onChange={(event, newValue) =>
                  handleEditTraineeChange(
                    "coInterviewerDesignationId",
                    newValue
                  )
                }
                getOptionLabel={(option) => option?.name}
                filterOptions={(options, params) =>
                  filterOptionsWithSelectAllDesignations(
                    options,
                    params,
                    editEvaluationInterviewerDetails?.coInterviewerCompetencyId
                      ?.designations?.results?.length,
                    coInterviewerWithAllDesignation
                  )
                }
                value={
                  editEvaluationInterviewerDetails?.coInterviewerDesignationId
                    ? editEvaluationInterviewerDetails?.coInterviewerDesignationId
                    : { name: "All", all: true }
                }
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        (option?.name === "All" &&
                          option?.name === value?.inputValue) ||
                        option?.id ===
                          editEvaluationInterviewerDetails
                            ?.coInterviewerDesignationId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
                disableClearable
              />
              <SimpleAutoComplete
                disabled={!editEvaluationInterviewerDetails?.leadInterviewerId}
                label="Co-Interviewer"
                placeholder="Select Co-Interviewer"
                options={coInterviewerOptions?.filter(
                  (user) =>
                    user?.id !==
                    editEvaluationInterviewerDetails?.leadInterviewerId?.id
                )}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("coInterviewerId", newValue)
                }
                getOptionLabel={(option) => option?.fullName}
                value={editEvaluationInterviewerDetails?.coInterviewerId}
                error={!!errors?.coInterviewerId}
                helperText={errors?.coInterviewerId}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id ===
                        editEvaluationInterviewerDetails?.coInterviewerId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.fullName}`}</Box>
                        <Typography variant="caption" className="filter-title ">
                          {`${option?.email}`}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0 2rem 1.875rem" }}>
          <Button
            onClick={handleCloseEditTrainee}
            variant="outlined"
            color="secondary"
            className="add-role-btn"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmitEditTrainee}
            variant="contained"
            color="secondary"
            className="add-role-btn"
            disabled={!editTraineeDetailsChanged}
          >
            <Typography variant="outlineBtnLabel">Update</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditEvaluationInterviewer;

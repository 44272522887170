import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addBatchCreateApi,
  addBatchReCreateApi,
  createBulkBatchesApi,
  deleteBootcampModuleBatchApi,
  deleteBootcampModuleViewApi,
  editBootcampModuleApi,
  editBootcampModuleBatchApi,
  getBatchAttendeesApi,
  getBootcampApi,
  getBootcampByIdApi,
  getBootcampModuleBatchApi,
  getBootcampModuleViewApi,
  getRoomListApi,
} from "../services/bootcampModuleView";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  bootcamp: [],
  moduleData: {},
  moduleBatch: [],
  rooms: [],
  loading: false,
  moduleBatchLoading: false,
  error: null,
  attendees: [],
  bootcampLoading: false,
  roomLoading: false,
  batchAttendeesLoading: false,
  addBatchCreateLoading: false,
  editBootcampModuleBatchLoading: false,
};

export const getBootcampModuleView = createAsyncThunk(
  "getBootcampModuleView",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampModuleViewApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBootcampModuleView = createAsyncThunk(
  "deleteBootcampModuleView",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await deleteBootcampModuleViewApi(data);
      toast.custom(
        <CustomToaster
          message="Bootcamp Module Deleted Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBootcampModuleBatch = createAsyncThunk(
  "getBootcampModuleBatch",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampModuleBatchApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editBootcampModuleBatch = createAsyncThunk(
  "editBootcampModuleBatch",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await editBootcampModuleBatchApi(data);
      if (callApi) {
        toast.custom(
          <CustomToaster
            message="Batch Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addBatchCreate = createAsyncThunk(
  "addBatchCreate",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addBatchCreateApi(data);
      if (callApi) {
        toast.custom(
          <CustomToaster
            message="Batch Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addBatchReCreate = createAsyncThunk(
  "addBatchReCreate",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addBatchReCreateApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBootcampModuleBatch = createAsyncThunk(
  "deleteBootcampModuleBatch",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await deleteBootcampModuleBatchApi(data);
      if (callApi) {
        toast.custom(
          <CustomToaster
            message="Batch Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBootcamp = createAsyncThunk(
  "getBootcamp",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editBootcampModule = createAsyncThunk(
  "editBootcampModule",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await editBootcampModuleApi(data);
      toast.custom(
        <CustomToaster
          message="Bootcamp Module Updated Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBatchAttendees = createAsyncThunk(
  "getBatchAttendees",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBatchAttendeesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoomList = createAsyncThunk(
  "getRoomList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getRoomListApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getBootcampById = createAsyncThunk(
  "getBootcampById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBulkBatches = createAsyncThunk(
  "createBulkBatches",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await createBulkBatchesApi(data);
      if (callApi?.data) {
        toast.custom(
          <CustomToaster
            message="Batches Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bootcampModuleView = createSlice({
  name: "bootcampModuleView",
  initialState,
  reducers: {
    removeAttendees(state) {
      state.attendees = [];
    },
    clearModuleData(state) {
      state.moduleData = {};
    },
    clearModuleBatch(state) {
      state.moduleBatch = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBootcampModuleView.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBootcampModuleView.fulfilled, (state, action) => {
        state.loading = false;
        state.moduleData = action.payload;
      })
      .addCase(getBootcampModuleView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBootcampModuleView.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBootcampModuleView.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteBootcampModuleView.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBootcampModuleBatch.pending, (state) => {
        state.moduleBatchLoading = true;
      })
      .addCase(getBootcampModuleBatch.fulfilled, (state, action) => {
        state.moduleBatchLoading = false;
        state.moduleBatch = action.payload;
      })
      .addCase(getBootcampModuleBatch.rejected, (state, action) => {
        state.moduleBatchLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteBootcampModuleBatch.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBootcampModuleBatch.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteBootcampModuleBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBootcamp.pending, (state) => {
        state.bootcampLoading = true;
      })
      .addCase(getBootcamp.fulfilled, (state, action) => {
        state.bootcampLoading = false;
        state.bootcamp = action.payload;
      })
      .addCase(getBootcamp.rejected, (state, action) => {
        state.bootcampLoading = false;
        state.error = action.payload;
      })
      .addCase(editBootcampModule.pending, (state) => {
        state.loading = true;
      })
      .addCase(editBootcampModule.fulfilled, (state, action) => {
        state.loading = false;
        state.bootcamp = action.payload;
      })
      .addCase(editBootcampModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editBootcampModuleBatch.pending, (state) => {
        state.editBootcampModuleBatchLoading = true;
      })
      .addCase(editBootcampModuleBatch.fulfilled, (state) => {
        state.editBootcampModuleBatchLoading = false;
      })
      .addCase(editBootcampModuleBatch.rejected, (state, action) => {
        state.editBootcampModuleBatchLoading = false;
        state.error = action.payload;
      })
      .addCase(addBatchCreate.pending, (state) => {
        state.addBatchCreateLoading = true;
      })
      .addCase(addBatchCreate.fulfilled, (state) => {
        state.addBatchCreateLoading = false;
      })
      .addCase(addBatchCreate.rejected, (state, action) => {
        state.addBatchCreateLoading = false;
        state.error = action.payload;
      })
      .addCase(addBatchReCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBatchReCreate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addBatchReCreate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBatchAttendees.pending, (state) => {
        state.batchAttendeesLoading = true;
      })
      .addCase(getBatchAttendees.fulfilled, (state, action) => {
        state.batchAttendeesLoading = false;
        state.attendees = action.payload;
      })
      .addCase(getBatchAttendees.rejected, (state, action) => {
        state.batchAttendeesLoading = false;
        state.error = action.payload;
      })
      .addCase(getRoomList.pending, (state) => {
        state.roomLoading = true;
      })
      .addCase(getRoomList.fulfilled, (state, action) => {
        state.roomLoading = false;
        state.rooms = action.payload;
      })
      .addCase(getRoomList.rejected, (state, action) => {
        state.roomLoading = false;
        state.error = action.payload;
      })
      .addCase(createBulkBatches.pending, (state) => {
        state.addBatchCreateLoading = true;
      })
      .addCase(createBulkBatches.fulfilled, (state, action) => {
        state.addBatchCreateLoading = false;
      })
      .addCase(createBulkBatches.rejected, (state, action) => {
        state.addBatchCreateLoading = false;
        state.error = action.payload;
      });
  },
});

export const { removeAttendees, clearModuleData, clearModuleBatch } =
  bootcampModuleView.actions;
export default bootcampModuleView.reducer;

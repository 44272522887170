import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { toast } from "react-hot-toast";
import {
  addAssessmentApi,
  editAssessmentApi,
  fetchAssessmentQuestionsApi,
  fetchModulesListApi,
  fetchSessionsListApi,
  getAssessmentApi,
  getAssessmentByIdApi,
  getBootcampByIdOnAssessmentApi,
  getModuleByIdOnAssessmentApi,
} from "../services/createAssessment";

const initialState = {
  modulesListData: [],
  sessionsListData: [],
  assessmentListData: {},
  assessmentQuestionsData: [],
  assessmentInfo: null,
  getBootcampDataById: null,
  getModuleDataById: null,
  loading: false,
  error: null,
  modulesListLoader: false,
  sessionsListLoader: false,
  addAssessmentLoader: false,
  editAssessmentLoader: false,
  assessmentListLoader: false,
  getAssessmentLoader: false,
  bootcampDataLoading: false,
  moduleDataLoading: false,
  assessmentQuestionsLoader: false,
};

export const getBootcampByIdOnAssessment = createAsyncThunk(
  "getBootcampByIdOnAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampByIdOnAssessmentApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getModuleByIdOnAssessment = createAsyncThunk(
  "getModuleByIdOnAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getModuleByIdOnAssessmentApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssessment = createAsyncThunk(
  "getAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssessmentApi(data);
      toast.custom(
        <CustomToaster
          message="Assessment Added Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssessmentById = createAsyncThunk(
  "getAssessmentById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssessmentByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAssessment = createAsyncThunk(
  "addAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addAssessmentApi(data);
      toast.custom(
        <CustomToaster
          message="Assessment Added Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAssessment = createAsyncThunk(
  "editAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await editAssessmentApi(data);
      toast.custom(
        <CustomToaster
          message="Assessment Edited Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getModulesList = createAsyncThunk(
  "getModulesList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await fetchModulesListApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSessionsList = createAsyncThunk(
  "getSessionsList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await fetchSessionsListApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setAssessmentQuestionsData = createAsyncThunk(
  "setAssessmentQuestionsData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await fetchAssessmentQuestionsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createAssessment = createSlice({
  name: "createAssessment",
  initialState,
  reducers: {
    clearAssessmentInfo(state) {
      state.assessmentInfo = null;
    },
    clearBootcampInfo(state) {
      state.getBootcampDataById = null;
    },
    clearModuleInfo(state) {
      state.getModuleDataById = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAssessmentQuestionsData.pending, (state) => {
        state.assessmentQuestionsLoader = true;
      })
      .addCase(setAssessmentQuestionsData.fulfilled, (state, action) => {
        state.assessmentQuestionsLoader = false;
        state.assessmentQuestionsData = action.payload;
      })
      .addCase(setAssessmentQuestionsData.rejected, (state, action) => {
        state.assessmentQuestionsLoader = false;
        state.error = action.payload;
      })
      .addCase(getBootcampByIdOnAssessment.pending, (state) => {
        state.bootcampDataLoading = true;
      })
      .addCase(getBootcampByIdOnAssessment.fulfilled, (state, action) => {
        state.bootcampDataLoading = false;
        state.getBootcampDataById = action.payload;
      })
      .addCase(getBootcampByIdOnAssessment.rejected, (state, action) => {
        state.bootcampDataLoading = false;
        state.error = action.payload;
      })
      .addCase(getModuleByIdOnAssessment.pending, (state) => {
        state.moduleDataLoading = true;
      })
      .addCase(getModuleByIdOnAssessment.fulfilled, (state, action) => {
        state.moduleDataLoading = false;
        state.getModuleDataById = action.payload;
      })
      .addCase(getModuleByIdOnAssessment.rejected, (state, action) => {
        state.moduleDataLoading = false;
        state.error = action.payload;
      })
      .addCase(getAssessment.pending, (state) => {
        state.assessmentListLoader = true;
      })
      .addCase(getAssessment.fulfilled, (state, action) => {
        state.assessmentListLoader = false;
        state.assessmentListData = action.payload;
      })
      .addCase(getAssessment.rejected, (state, action) => {
        state.assessmentListLoader = false;
        state.error = action.payload;
      })
      .addCase(getAssessmentById.pending, (state) => {
        state.getAssessmentLoader = true;
      })
      .addCase(getAssessmentById.fulfilled, (state, action) => {
        state.getAssessmentLoader = false;
        state.assessmentInfo = action.payload;
      })
      .addCase(getAssessmentById.rejected, (state, action) => {
        state.getAssessmentLoader = false;
        state.error = action.payload;
      })
      .addCase(addAssessment.pending, (state) => {
        state.addAssessmentLoader = true;
      })
      .addCase(addAssessment.fulfilled, (state, action) => {
        state.addAssessmentLoader = false;
      })
      .addCase(addAssessment.rejected, (state, action) => {
        state.addAssessmentLoader = false;
        state.error = action.payload;
      })
      .addCase(editAssessment.pending, (state) => {
        state.editAssessmentLoader = true;
      })
      .addCase(editAssessment.fulfilled, (state, action) => {
        state.editAssessmentLoader = false;
      })
      .addCase(editAssessment.rejected, (state, action) => {
        state.editAssessmentLoader = false;
        state.error = action.payload;
      })
      .addCase(getModulesList.pending, (state) => {
        state.sessionsListLoader = true;
      })
      .addCase(getModulesList.fulfilled, (state, action) => {
        state.sessionsListLoader = false;
        state.sessionsListData = action.payload;
      })
      .addCase(getModulesList.rejected, (state, action) => {
        state.sessionsListLoader = false;
        state.error = action.payload;
      })
      .addCase(getSessionsList.pending, (state) => {
        state.sessionsListLoader = true;
      })
      .addCase(getSessionsList.fulfilled, (state, action) => {
        state.sessionsListLoader = false;
        state.sessionsListData = action.payload;
      })
      .addCase(getSessionsList.rejected, (state, action) => {
        state.sessionsListLoader = false;
        state.error = action.payload;
      });
  },
});
export const { clearAssessmentInfo, clearBootcampInfo, clearModuleInfo } =
  createAssessment.actions;
export default createAssessment.reducer;

import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function fetchAssessmentListingApi(data) {
  let apiUrl = `${apiBasePath}/assessment?max=20`;
  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }
  if (
    data?.moduleId !== undefined &&
    data?.moduleId !== null &&
    data?.moduleId !== ""
  ) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }
  if (data?.type !== undefined && data?.type !== null && data?.type !== "") {
    apiUrl += `&type=${data?.type}`;
  }
  if (data?.max !== undefined && data?.max !== null && data?.max !== "") {
    apiUrl += `&max=${data?.max}`;
  }
  if (
    data?.sortBy !== undefined &&
    data?.sortBy !== null &&
    data?.sortBy !== ""
  ) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }
  if (
    data?.sortOrder !== undefined &&
    data?.sortOrder !== null &&
    data?.sortOrder !== ""
  ) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  if (
    data?.offset !== undefined &&
    data?.offset !== null &&
    data?.offset !== ""
  ) {
    apiUrl += `&offset=${data?.offset}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function activateInactivateAssessmentApi(id) {
  const options = {
    method: "POST",
    url: `${apiBasePath}/assessment/toggleActive?assessmentId=${id}`,
    data: id,
  };
  return ApiService(options);
}

export function deleteAssessmentApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiBasePath}/assessment/${id}`,
  };
  return ApiService(options);
}

export function getBootcampForAssessmentApi({ bootcampId }) {
  let apiUrl = `${apiBasePath}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getModuleForAssessmentApi(data) {
  let apiUrl = `${apiBasePath}/modules/${data.moduleId}`;

  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `?bootcampId=${data?.bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

import React from "react";
import "./table.scss";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconButtons from "../IconButtons/IconButtons";
import {
  clipboard_active_icon,
  clone_active_icon,
  clone_disabled_icon,
  delete_active_icon,
  delete_disabled_icon,
  edit_active_icon,
  edit_disabled_icon,
  lock_disabled_icon,
  lock_enabled_icon,
  plus_dashed_active_icon,
  plus_dashed_default_icon,
  reopen_disabled_icon,
  reopen_icon,
  unlock_disabled_icon,
  unlock_enabled_icon,
} from "../../constants/icons";
import TablePagination from "../TablePagination/TablePagination";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { font_13, font_13_icon, font_21 } from "../../utils/utils";
import Constants from "../../constants/constants";

const TableWithPagination = ({
  topicData,
  handleExercise,
  handleAssessmentQuestions,
  handleSessions,
  handleTextModalOpen,
  handleEditInputModalOpen,
  handleDeleteModalOpen,
  totalPages,
  handlePageChange,
  handleResourceModalOpen,
  handleResourceTableOpen,
  totalResult,
  currentPage,
  handleLockTopic,
  handleCloneTopic,
}) => {
  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              <TableCell
                align="left"
                sx={{ minWidth: "13rem", maxWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Name</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Description</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Agenda</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Resources</Typography>
              </TableCell>
              <TableCell
                className="coverHeader"
                align="center"
                sx={{ minWidth: "15.813rem" }}
              >
                <Box className="double-head-table double-head-table-border-right">
                  <Typography
                    variant="font_11_bold_700"
                    className="double-head-table-title"
                  >
                    Questions
                  </Typography>
                  <Box
                    className="double-head-table-sub"
                    sx={{ minWidth: "15rem" }}
                  >
                    <Typography variant="font_13_bold" className="width-7">
                      Assessment
                    </Typography>
                    <Typography variant="font_13_bold" className="width-7">
                      Exercise
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Sessions</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1 padding-right-1"
              >
                <Typography variant="font_13_bold">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topicData?.data?.results.map(
              (
                {
                  id,
                  name,
                  agenda,
                  description,
                  totalAssessmentQuestionCount,
                  exerciseQuestions,
                  deleted,
                  resources,
                  sessions,
                  locked,
                  competency,
                  permission,
                },
                index
              ) => {
                return (
                  <TableRow className="table-body-row" hover key={id}>
                    <TableCell align="left" className="padding-left-1">
                      <Typography
                        variant="font_12"
                        sx={{ textOverflow: "ellipsis" }}
                      >
                        {name.length > 50
                          ? name.substring(0, 50) + "..."
                          : name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="padding-left-1"
                      align="left"
                      onClick={(e) =>
                        handleTextModalOpen(
                          e,
                          description,
                          "Description not available",
                          false
                        )
                      }
                    >
                      <Typography
                        variant="font_12"
                        className="topic-description"
                      >
                        {description.length > 50
                          ? description.substring(0, 50) + "..."
                          : description}
                      </Typography>
                    </TableCell>
                    <TableCell className="padding-left-1" align="center">
                      <IconButtons
                        width={font_13}
                        height={font_13}
                        image={clipboard_active_icon}
                        tooltip="Show Agenda"
                        handleClick={(e) =>
                          handleTextModalOpen(
                            e,
                            agenda,
                            "No agenda Available",
                            true
                          )
                        }
                      />
                    </TableCell>
                    <TableCell className="padding-left-1" align="center">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {permission?.canListResource ? (
                          <BootstrapTooltip title="Show Resources">
                            <Button
                              sx={{ minWidth: 0 }}
                              onClick={(e) =>
                                handleResourceTableOpen(
                                  e,
                                  id,
                                  resources?.totalResults
                                )
                              }
                            >
                              <Typography
                                variant="font_14_bold"
                                color="secondary"
                              >
                                <u>{resources?.totalResults}</u>
                              </Typography>
                            </Button>
                          </BootstrapTooltip>
                        ) : (
                          <Typography variant="font_14_bold">
                            {resources?.totalResults}
                          </Typography>
                        )}
                        {permission?.canViewAddResource && (
                          <IconButtons
                            width={font_21}
                            height={font_21}
                            image={plus_dashed_active_icon}
                            tooltip="Add Resource"
                            handleClick={() => handleResourceModalOpen(id)}
                            disabled={!permission?.canAddResource}
                            disabledImage={plus_dashed_default_icon}
                            disabledTooltip={`Resources for this topic can be added by SPOCs of ${competency?.name} competency`}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="double-head-table-body">
                        <Box className="width-7">
                          <BootstrapTooltip title="Show Assessment Questions">
                            <Button
                              sx={{ minWidth: 0 }}
                              size="small"
                              onClick={() => handleAssessmentQuestions(id)}
                            >
                              <Typography
                                variant="font_14_bold"
                                color="secondary"
                              >
                                <u>
                                  {totalAssessmentQuestionCount
                                    ? totalAssessmentQuestionCount
                                    : 0}
                                </u>
                              </Typography>
                            </Button>
                          </BootstrapTooltip>
                        </Box>
                        {permission?.canListExerciseQuestion ? (
                          <Box className="width-7">
                            <BootstrapTooltip title="Show Questions">
                              <Button
                                sx={{ minWidth: 0 }}
                                size="small"
                                onClick={() => handleExercise(id)}
                              >
                                <Typography
                                  variant="font_14_bold"
                                  color="secondary"
                                >
                                  <u>{exerciseQuestions?.totalResults}</u>
                                </Typography>
                              </Button>
                            </BootstrapTooltip>
                          </Box>
                        ) : (
                          <Typography variant="font_14_bold">
                            {exerciseQuestions?.totalResults}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center" className="padding-left-1">
                      <BootstrapTooltip title="Show Sessions">
                        <Button
                          sx={{ minWidth: 0 }}
                          size="small"
                          onClick={() => handleSessions(id)}
                        >
                          <Typography variant="font_14_bold" color="secondary">
                            <u>{sessions?.totalResults}</u>
                          </Typography>
                        </Button>
                      </BootstrapTooltip>
                    </TableCell>

                    <TableCell
                      align="center"
                      className="padding-left-1 padding-right-1"
                    >
                      <Box className="topic-action-icons">
                        {permission?.canViewEditTopic && (
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={edit_active_icon}
                            tooltip="Edit Topic"
                            classList="margin-right-1"
                            handleClick={() =>
                              handleEditInputModalOpen(
                                id,
                                name,
                                description,
                                agenda,
                                exerciseQuestions?.results || []
                              )
                            }
                            disabledImage={edit_disabled_icon}
                            disabled={!permission?.canEditTopic}
                            disabledTooltip={`This topic can be edited by SPOCs of ${competency?.name} competency`}
                          />
                        )}
                        {permission.canViewCloneTopic && (
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={clone_active_icon}
                            disabledImage={clone_disabled_icon}
                            tooltip="Clone Topic"
                            classList="margin-right-1"
                            handleClick={() =>
                              handleCloneTopic(id, name, description, agenda)
                            }
                            disabled={!permission.canCloneTopic}
                          />
                        )}
                        <IconButtons
                          width={font_13_icon}
                          height={font_13_icon}
                          image={delete_active_icon}
                          disabledImage={delete_disabled_icon}
                          tooltip="Delete Topic"
                          classList="margin-right-1"
                          handleClick={() => handleDeleteModalOpen(id, "Topic")}
                          disabled={!permission.canDeleteTopic}
                          disabledTooltip={
                            permission?.isTopicLockedForUser
                              ? `This topic can be deleted by SPOCs of ${competency?.name} competency`
                              : `This topic cannot be deleted, since it has sessions associated with it.`
                          }
                        />
                        {permission?.canViewLockTopic && (
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={
                              locked ? lock_enabled_icon : unlock_enabled_icon
                            }
                            tooltip={locked ? "Un-Lock Topic" : "Lock Topic"}
                            handleClick={() => handleLockTopic(id, !locked)}
                            disabled={!permission.canLockTopic}
                            disabledImage={
                              locked ? lock_disabled_icon : unlock_disabled_icon
                            }
                            disabledTooltip={`This topic can be ${
                              locked ? "Unlocked" : "Locked"
                            } by SPOCs of ${competency?.name} competency`}
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">Total {totalResult} Records</Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default TableWithPagination;

import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getCommentListApi(data) {
  let apiUrl = `${apiBasePath}/comment?paginated=false`;

  if (data?.exerciseId !== undefined) {
    apiUrl += `&exerciseId=${data?.exerciseId}`;
  }

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset * 20}`;
  }

  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function addCommentApi(data) {
  let apiUrl = `${apiBasePath}/comment`;

  if (data?.exerciseId !== undefined) {
    apiUrl += `?exerciseId=${data?.exerciseId}`;
  }

  if (data?.comment !== undefined) {
    apiUrl += `&comment=${encodeURIComponent(data?.comment)}`;
  }

  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function editCommentApi(data) {
  let apiUrl = `${apiBasePath}/comment`;

  if (data?.commentId !== undefined) {
    apiUrl += `?commentId=${data?.commentId}`;
  }

  if (data?.comment !== undefined) {
    apiUrl += `&comment=${encodeURIComponent(data?.comment)}`;
  }

  const options = {
    method: "PUT",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function deleteCommentApi(data) {
  let apiUrl = `${apiBasePath}/comment`;

  if (data?.commentId !== undefined) {
    apiUrl += `/${data?.commentId}`;
  }

  const options = {
    method: "DELETE",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getQuestionListApi(data) {
  let apiUrl = `${apiBasePath}/exerciseQuestions?id=${data.id}&type=${data.type}`;

  if (data?.sortOrder !== undefined) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }
  if (
    data?.moduleId !== undefined &&
    data?.moduleId !== null &&
    data?.moduleId !== ""
  ) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }
  if (
    data?.topicId !== undefined &&
    data?.topicId !== null &&
    data?.topicId !== ""
  ) {
    apiUrl += `&topicId=${data?.topicId}`;
  }
  if (
    data?.sessionId !== undefined &&
    data?.sessionId !== null &&
    data?.sessionId !== ""
  ) {
    apiUrl += `&sessionId=${data?.sessionId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function deleteQuestionApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiBasePath}/exerciseQuestions/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function addQuestionApi(data) {
  const options = {
    method: "POST",
    url: `${apiBasePath}/exerciseQuestions`,
    data: data,
  };
  return ApiService(options);
}

export function updateQuestionApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiBasePath}/exerciseQuestions`,
    data: data,
  };
  return ApiService(options);
}

export function getAssociateQuestionsApi(data) {
  let apiUrl = `${apiBasePath}/exerciseQuestions?paginated=false`;

  if (data?.id !== undefined) {
    apiUrl += `&id=${data?.id}`;
  }

  if (data?.type !== undefined) {
    apiUrl += `&type=${data?.type}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function associateQuestionsApi(data) {
  let apiUrl = `${apiBasePath}/session/associateExercises`;
  const { sessionId, associateExerciseIds } = data;
  const newData = {
    sessionId,
    associateExerciseIds,
  };
  const options = {
    method: "POST",
    url: apiUrl,
    data: newData,
  };
  return ApiService(options);
}

export function getTopicViaIdApi(data) {
  const options = {
    method: "GET",
    url: `${apiBasePath}/topics/${data.id}`,
    data: data,
  };
  return ApiService(options);
}

export function disassociateQuestionApi(data) {
  let apiUrl = `${apiBasePath}/session/disassociateExercise`;
  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

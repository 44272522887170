import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getExercisesListApi(data) {
  let apiUrl = `${apiBasePath}/session/status?max=20`;

  if (data?.bootcampId) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }

  if (data?.asAttendee !== undefined) {
    apiUrl += `&asAttendee=${data?.asAttendee}`;
  }

  if (data?.moduleId) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }

  if (data?.sessionId) {
    apiUrl += `&sessionId=${data?.sessionId}`;
  }

  if (data?.submittedById) {
    apiUrl += `&submittedById=${data?.submittedById}`;
  }

  if (data?.status) {
    apiUrl += `&status=${data?.status}`;
  }

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset * 20}`;
  }

  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getFiltersDataApi(data) {
  let apiUrl = `${apiBasePath}/utilities/filtersForSessionExerciseListing?paginated=false`;

  if (data?.asAttendee !== undefined) {
    apiUrl += `&asAttendee=${data?.asAttendee}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function addSubmitDetailsApi(data) {
  const { submitDetails, exerciseId } = data;

  let apiUrl = `${apiBasePath}/sessionExercise`;

  if (exerciseId !== undefined) {
    apiUrl += `/${exerciseId}`;
  }

  const options = {
    method: "PUT",
    url: apiUrl,
    data: submitDetails,
  };
  return ApiService(options, true, true);
}

export function getBootcampListApi(data) {
  let apiUrl = `${apiBasePath}/bootcamps?paginated=false`;

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset}`;
  }

  if (data?.sortBy) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  if (data?.grouped) {
    apiUrl += `&grouped=${data?.grouped}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };

  return ApiService(options);
}

export function getOptionsForEditReviewerApi(data) {
  let apiUrl = `${apiBasePath}/utilities/optionsForEditReviewer?sessionId=${data?.sessionId}`;
  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getAssessmentEvaluationByIdApi(id) {
  const options = {
    method: "GET",
    url: `${apiBasePath}/assessmentEvaluation/${id}?includeQuestions=false&includeTopicQuestions=true`,
    data: id,
  };
  return ApiService(options);
}

export function getBootcampByIdApi({ bootcampId }) {
  let apiUrl = `${apiBasePath}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

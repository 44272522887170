import { apiBasePath } from "../../constants/constants";
import ApiService from "../../utils/api-service";

export function getDetailsApi(data) {
  let apiUrl = `${apiBasePath}/session/${data.sessionId}?includeResources=true&includePresenters=true`;

  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }

  if (
    data?.moduleId !== undefined &&
    data?.moduleId !== null &&
    data?.moduleId !== ""
  ) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }

  if (
    data?.topicId !== undefined &&
    data?.topicId !== null &&
    data?.topicId !== ""
  ) {
    apiUrl += `&topicId=${data?.topicId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getResourcesApi(data) {
  let apiUrl = `${apiBasePath}/resources?id=${data.sessionId}&type=SESSION`;

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getPresentersApi(data) {
  let apiUrl = `${apiBasePath}/presenter?sessionId=${data?.sessionId}&paginated=false`;

  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function deleteSessionApi(data) {
  let apiUrl = `${apiBasePath}/session/${data}`;

  const options = {
    method: "DELETE",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getBatchesApi(data) {
  let apiUrl = `${apiBasePath}/moduleBatches?moduleId=${data.moduleId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

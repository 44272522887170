import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./assessmentQuestions.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

import { useDispatch, useSelector } from "react-redux";
import {
  clearAssessmentQuestionsData,
  deleteAssessmentQuestion,
  deleteOption,
  getAssessmentQuestionById,
  getTopicById,
  setAssessmentQuestionsData,
  toggleQuestionAssessment,
} from "../../store/assessmentQuestions";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import {
  ROLE_TYPES,
  calculateFilterCount,
  font_13_icon,
} from "../../utils/utils";
import { useParams } from "react-router-dom/dist";
import AssessmentQuestionsCard from "../../components/AssessmentQuestionsCard/AssessmentQuestionsCard";
import { filter_active_icon } from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import AssessmentFilter from "./AssessmentFilter";
import AddAssessmentQuestion from "./AddAssessmentQuestion";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";

const questionsToAppear = () => {
  const screenSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const MaxData = (screenSize?.height - 208) / 110 + 2;
  return MaxData;
};

const AssessmentQuestions = () => {
  const initialFilter = {
    title: "",
    type: [],
    difficultyLevel: [],
    showActive: "showAll",
  };
  const params = useParams();
  const dispatch = useDispatch();
  const questionTiles = questionsToAppear();
  const tilesCount = questionTiles > 10 ? Math.floor(questionTiles) : 10;
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const isAdmin = ROLE_TYPES.isAdmin(roles);
  const assessmentQuestions = useSelector(
    (state) => state?.assessmentQuestions
  );
  const singleTopicData = assessmentQuestions?.singleTopicData?.data;
  const topicDataLoading = assessmentQuestions?.topicDataLoader;
  const updatedQuestionData =
    assessmentQuestions?.getAssessmentQuestionByIdData;
  const updatedQuestionLoader =
    assessmentQuestions?.getAssessmentQuestionByIdLoading;
  const editAssessmentQuestionLoader =
    assessmentQuestions?.editAssessmentQuestionLoading;
  const addAssessmentQuestionLoader = assessmentQuestions?.addAssessmentLoading;
  const toggleQuestionAssessmentLoader =
    assessmentQuestions?.toggleQuestionAssessmentLoading;

  const {
    canViewCreateAssessmentQuestion,
    canCreateAssessmentQuestion,
    createAssessmentQuestionMessage,
  } = singleTopicData?.permission || {};
  const assessmentQuestionsData = assessmentQuestions?.assessmentQuestionsData;
  const assessmentQuestionsDataResults = assessmentQuestionsData?.data?.results;
  const loading = assessmentQuestions?.assessmentQuestionsLoader;
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [filterActivated, setFilterActivated] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterAssessment, setFilterAssessment] = useState(initialFilter);
  const [initialFilterValues, setInitialFilterValues] = useState(initialFilter);
  const utilityLoading = useSelector((state) => state?.utility?.loading);
  const questionTypes = useSelector(
    (state) => state?.utility?.constants?.questionTypes
  );
  const difficultyLevels = useSelector(
    (state) => state?.utility?.constants?.difficultyLevels
  );
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [newData, setNewData] = useState(true);
  const [offset, setOffset] = useState(0);

  const fetchMoreData = async () => {
    setLoad(true);
    const newOffset = offset;
    const data = {
      ...filterAssessment,
      topicId: params.topicId,
      type: filterAssessment?.type?.map((item) => item?.key),
      difficultyLevel: filterAssessment?.difficultyLevel?.map(
        (item) => item?.key
      ),
      showActive:
        filterAssessment?.showActive === "showActive"
          ? true
          : filterAssessment?.showActive === "showInactive"
          ? false
          : null,
      offset: newOffset,
      max: tilesCount,
    };
    await dispatch(setAssessmentQuestionsData(data));
  };

  const [initialTurn, setInitialTurn] = useState(true);
  useEffect(() => {
    setDataLoading(true);
    if (assessmentQuestionsDataResults && !initialTurn) {
      const newItems = assessmentQuestionsDataResults || [];
      if (newData) {
        setItems(newItems);
        setDataLoading(false);
        setNewData(false);
        setOffset(tilesCount);
      } else {
        const filteredNewItems = newItems.filter(
          (newItem) => !items.some((prevItem) => prevItem.id === newItem.id)
        );
        if (filteredNewItems.length > 0) {
          setItems((prevItems) => [...prevItems, ...filteredNewItems]);
          setDataLoading(false);
          setOffset(offset + tilesCount);
        }
      }
      setDataLoading(false);
      setHasMore(true);
      setLoad(false);
      if (newItems.length === 0) {
        setHasMore(false);
      }
    }
  }, [assessmentQuestionsDataResults]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setInitialTurn(false);
      setNewData(true);
      await dispatch(getTopicById({ id: params.topicId }));
      const newFilterFields = {
        ...filterAssessment,
        type: questionTypes?.map((item) => item),
        difficultyLevel: difficultyLevels?.map((item) => item),
      };
      await dispatch(
        setAssessmentQuestionsData({
          ...newFilterFields,
          topicId: params.topicId,
          offset: 0,
          max: tilesCount,
          title: newFilterFields?.title.trim(),
          type: newFilterFields?.type?.map((item) => item?.key),
          difficultyLevel: newFilterFields?.difficultyLevel?.map(
            (item) => item?.key
          ),
          showActive:
            newFilterFields.showActive === "showActive"
              ? true
              : newFilterFields.showActive === "showInactive"
              ? false
              : null,
        })
      );
      setFilterAssessment(newFilterFields);
      setFilterCount(calculateFilterCount(newFilterFields));
      setFilterActivated(calculateFilterCount(newFilterFields));
      setInitialFilterValues(newFilterFields);
      setIsLoading(false);
    };
    if (questionTypes) {
      fetchData();
    }

    return () => {
      dispatch(clearAssessmentQuestionsData());
    };
  }, [dispatch, questionTypes]);

  useEffect(() => {
    setIsError(assessmentQuestions?.error);
  }, [assessmentQuestions.error, isError]);

  /////////// handle bootcamp on add and delete module///////////////
  const replaceItemFromPayload = (payloadData) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === payloadData.id ? payloadData : item))
    );
  };

  useEffect(() => {
    if (updatedQuestionData) {
      replaceItemFromPayload(updatedQuestionData?.data);
    }
  }, [updatedQuestionData]);
  //-----------------------------

  const [optionModalOpen, setOptionModalOpen] = useState(false);
  const [addNewOption, setAddNewOption] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [question, setQuestion] = useState(null);
  const [optionId, setOptionId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [isCloneQuestion, setIsCloneQuestion] = useState(false);
  const [addQuestionOpen, setAddQuestionOpen] = useState(false);
  const totalResult = assessmentQuestionsData?.data?.totalResults;
  const questionOptions = assessmentQuestionsDataResults?.find((question) => {
    return question?.id === questionId;
  })?.questionOptions?.results;

  const handleAddQuestionModalOpen = async (bootcamp) => {
    setAddQuestionOpen(true);
  };
  const handleOptionModalOpen = (id) => {
    setOptionModalOpen(true);
    setAddNewOption(true);
    setQuestionId(id);
  };
  const optionEditModal = (optionInfo, questId) => {
    setOptionModalOpen(true);
    setOptionId(optionInfo?.id);
    setQuestionId(questId);
  };

  const handleEditQuestionModalOpen = (event, id, question) => {
    event.stopPropagation();
    setIsEditQuestion(true);
    setAddQuestionOpen(true);
    setQuestionId(id);
    setQuestion(question);
  };

  const cloneQuestionModalOpen = (event, question) => {
    event.stopPropagation();
    setIsCloneQuestion(true);
    setAddQuestionOpen(true);
    setQuestion(question);
  };

  const toggleQuestionModalOpen = async (event, status, questionId) => {
    event.stopPropagation();
    await dispatch(toggleQuestionAssessment({ questionId, active: status }));
    await dispatch(
      getAssessmentQuestionById({
        assessmentQuestionId: questionId,
      })
    );
  };
  const handleSwitchClick = (event) => {
    // Prevent the click event from bubbling up to the Accordion
    event.stopPropagation();
  };

  const handleDeleteModalOpen = (event, question, option = null) => {
    event.stopPropagation();
    setDeleteModalOpen(true);
    setQuestionId(question.id);
    setOptionId(option ? option.id : null);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setOptionId(null);
  };
  const handleDelete = async () => {
    if (optionId) {
      const updatedQuestionOptions = questionOptions?.filter(
        (option) => option.id !== optionId
      );
      const isCorrectOptionAvailable = updatedQuestionOptions?.some(
        (option) => option?.correct === true
      );
      await dispatch(deleteOption({ optionId, isCorrectOptionAvailable }));
      setQuestionId(null);
      setOptionId(null);
    } else {
      await dispatch(deleteAssessmentQuestion(questionId));
    }
    setIsLoading(true);
    setDeleteModalOpen(false);
    setNewData(true);
    setOffset(0);
    await dispatch(
      setAssessmentQuestionsData({
        ...filterAssessment,
        topicId: params.topicId,
        type: filterAssessment?.type?.map((item) => item?.key),
        difficultyLevel: filterAssessment?.difficultyLevel?.map(
          (item) => item?.key
        ),
        showActive:
          filterAssessment?.showActive === "showActive"
            ? true
            : filterAssessment?.showActive === "showInactive"
            ? false
            : null,
        offset: 0,
        max: tilesCount,
      })
    );
    setIsLoading(false);
  };
  //-------------------------------------------------------------------

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  //------------------------------ Breadcrumbs ------------------------------//

  const breadcrumbs = [
    { label: "Topics", url: "/topic" },
    { label: "Assessment Questions" },
  ];

  if ((loading && isLoading) || topicDataLoading || utilityLoading)
    return <Loader />;

  return (
    <>
      {(updatedQuestionLoader ||
        editAssessmentQuestionLoader ||
        addAssessmentQuestionLoader ||
        toggleQuestionAssessmentLoader) && <Loader />}
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">
            {singleTopicData?.name}
          </Typography>
          <Box className="header-right-icons">
            {canViewCreateAssessmentQuestion && (
              <BootstrapTooltip
                title={
                  !canCreateAssessmentQuestion
                    ? createAssessmentQuestionMessage
                    : null
                }
              >
                <Box>
                  <Button
                    className="action-head-btn"
                    variant="outlined"
                    color="secondary"
                    onClick={handleAddQuestionModalOpen}
                    disabled={!canCreateAssessmentQuestion}
                  >
                    <Typography variant="outlineBtnLabel">
                      Add Question
                    </Typography>
                  </Button>
                </Box>
              </BootstrapTooltip>
            )}
            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                tooltip="Add Filters"
                width={font_13_icon}
                height={font_13_icon}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                count={filterCount}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Paper
            elevation={0}
            container
            className="assessment-questions-parent-container"
            id="assessmentQuestionContainer"
          >
            <Box>
              {!loading && !items?.length ? (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Questions Exist"
                  />
                </Grid>
              ) : (
                items && (
                  <InfiniteScroll
                    dataLength={items?.length}
                    next={fetchMoreData}
                    hasMore={items?.length === totalResult ? false : hasMore}
                    loader={load && <Loader />}
                    scrollableTarget="assessmentQuestionContainer"
                  >
                    {items?.map((e, index) => (
                      <AssessmentQuestionsCard
                        index={index}
                        key={e.id}
                        handleOptionModalOpen={handleOptionModalOpen}
                        questionEditModal={(event, id, question) =>
                          handleEditQuestionModalOpen(event, id, question)
                        }
                        cloneQuestionModalOpen={(event, question) =>
                          cloneQuestionModalOpen(event, question)
                        }
                        toggleQuestionModalOpen={(event, status, questionId) =>
                          toggleQuestionModalOpen(event, status, questionId)
                        }
                        handleSwitchClick={handleSwitchClick}
                        optionEditModal={optionEditModal}
                        deleteModalOpenQuestion={(event) =>
                          handleDeleteModalOpen(event, e)
                        }
                        deleteModalOpenOption={(event, option) =>
                          handleDeleteModalOpen(event, e, option)
                        }
                        question={e}
                        id={e?.id}
                        isDeleted={e?.deleted}
                        isAdmin={isAdmin}
                        optionId={optionId}
                        setOptionId={setOptionId}
                        optionModalOpen={optionModalOpen}
                        setOptionModalOpen={setOptionModalOpen}
                        questionId={questionId}
                        addNewOption={addNewOption}
                        setAddNewOption={setAddNewOption}
                        deleteModalOpen={deleteModalOpen}
                      />
                    ))}
                  </InfiniteScroll>
                )
              )}
            </Box>
          </Paper>
        </Grid>
        <DeleteModal
          open={deleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDelete}
        />
        <AssessmentFilter
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
          filterActivated={filterActivated}
          setFilterActivated={setFilterActivated}
          filterCount={filterCount}
          setFilterCount={setFilterCount}
          setIsLoading={setIsLoading}
          initialFilter={initialFilter}
          initialFilterValues={initialFilterValues}
          tilesCount={tilesCount}
          setOffset={setOffset}
          setNewData={setNewData}
          setInitialTurn={setInitialTurn}
          filterAssessment={filterAssessment}
          setFilterAssessment={setFilterAssessment}
        />
        <AddAssessmentQuestion
          isEditQuestion={isEditQuestion}
          setIsEditQuestion={setIsEditQuestion}
          isCloneQuestion={isCloneQuestion}
          setIsCloneQuestion={setIsCloneQuestion}
          addQuestionOpen={addQuestionOpen}
          setAddQuestionOpen={setAddQuestionOpen}
          question={question}
          setQuestion={setQuestion}
          initialFilterValues={initialFilterValues}
          setFilterAssessment={setFilterAssessment}
          setFilterCount={setFilterCount}
          setFilterActivated={setFilterActivated}
          tilesCount={tilesCount}
          setIsLoading={setIsLoading}
          setNewData={setNewData}
          setOffset={setOffset}
        />
      </Grid>
    </>
  );
};

export default AssessmentQuestions;

import React from "react";
import {
  Box,
  Chip,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  delete_active_icon,
  delete_disabled_icon,
  down_sort_arrow_icon,
  edit_active_icon,
  edit_disabled_icon,
  list_eval_disabled_icon,
  list_eval_icon,
  up_sort_arrow_icon,
} from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";
import TablePagination from "../TablePagination/TablePagination";
import {
  evaluationStatusChip,
  font_13_icon,
  font_15,
  font_9,
} from "../../utils/utils";
import { authCookieName, env } from "../../config/config";
import Constants, {
  sortInAscending,
  sortInDescending,
} from "../../constants/constants";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";

const AssessmentListingTable = ({
  assessmentList,
  totalPages,
  handlePageChange,
  currentPage,
  handleSorting,
  sortBy,
  orderBy,
  viaModule,
  totalResult,
  handleEditAssessment,
  handleActivateInactivateAssessment,
  handleDeleteAssessment,
}) => {
  const params = useParams();
  const { bootcampId, moduleId } = params;

  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader>
          <TableHead className="table-head-container">
            <TableRow className="table-head-row" sx={{ height: "2.711rem" }}>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Title</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "title" && orderBy === "ASC" ? null : font_9
                      }
                      width={
                        sortBy === "title" && orderBy === "ASC" ? null : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "title")}
                      disabled={sortBy === "title" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    {(sortBy !== "title" || orderBy !== "DESC") && (
                      <IconButtons
                        height={
                          sortBy === "title" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "title" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() => handleSorting("DESC", "title")}
                        disabled={sortBy === "title" && orderBy === "DESC"}
                        tooltip={sortInDescending}
                      />
                    )}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Type</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "type" && orderBy === "ASC" ? null : font_9
                      }
                      width={
                        sortBy === "type" && orderBy === "ASC" ? null : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "type")}
                      disabled={sortBy === "type" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "type" && orderBy === "DESC" ? null : font_9
                      }
                      width={
                        sortBy === "type" && orderBy === "DESC" ? null : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "type")}
                      disabled={sortBy === "type" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Start Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "activeFromDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "activeFromDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "activeFromDate")}
                      disabled={
                        sortBy === "activeFromDate" && orderBy === "ASC"
                      }
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "activeFromDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "activeFromDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("DESC", "activeFromDate")
                      }
                      disabled={
                        sortBy === "activeFromDate" && orderBy === "DESC"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">End Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "activeToDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "activeToDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "activeToDate")}
                      disabled={sortBy === "activeToDate" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "activeToDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "activeToDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "activeToDate")}
                      disabled={sortBy === "activeToDate" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  Duration
                </Typography>
              </TableCell>
              {!viaModule && (
                <TableCell
                  align="center"
                  sx={{ minWidth: "10rem" }}
                  className="padding-left-1"
                >
                  <Typography
                    variant="font_13_bold"
                    className="flex-table-sort-head"
                  >
                    <Box className="table-sort-head">Module</Box>
                    <Box className="table-sort-icon">
                      <IconButtons
                        height={
                          sortBy === "moduleName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "moduleName" && orderBy === "ASC"
                            ? null
                            : font_9
                        }
                        image={up_sort_arrow_icon}
                        handleClick={() => handleSorting("ASC", "moduleName")}
                        disabled={sortBy === "moduleName" && orderBy === "ASC"}
                        tooltip={sortInAscending}
                      />
                      <IconButtons
                        height={
                          sortBy === "moduleName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "moduleName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() => handleSorting("DESC", "moduleName")}
                        disabled={sortBy === "moduleName" && orderBy === "DESC"}
                        tooltip={sortInDescending}
                      />
                    </Box>
                  </Typography>
                </TableCell>
              )}
              <TableCell className="padding-left-1" sx={{ minWidth: "6rem" }}>
                <Typography variant="font_13_bold">Status</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "10rem", maxWidth: "10rem", width: "7rem" }}
                className="padding-left-1 padding-right-2"
              >
                <Typography variant="font_13_bold">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assessmentList?.map((assessment, index) => {
              const active = assessment?.active;
              const assessmentId = assessment?.id;
              const title = assessment?.title;
              const type = assessment?.type;
              const startDate = assessment?.activeFromDate;
              const endDate = assessment?.activeToDate;
              const durationInMinutes = assessment?.durationInMinutes;
              const module = assessment?.module;
              const permissions = assessment?.permission || {};
              const status = evaluationStatusChip(assessment?.status);
              const {
                canViewEditAssessment,
                canEditAssessment,
                canViewToggleActive,
                canToggleActive,
                canViewListEvaluations,
                canListEvaluations,
                editAssessmentMessage,
                toggleActiveMessage,
                listEvaluationsMessage,
                canViewAssessment,
                canDeleteAssessment,
                canViewDeleteAssessment,
                deleteAssessmentMessage,
              } = permissions;

              return (
                <TableRow className="table-body-row" hover key={index}>
                  <TableCell align="left" className="padding-left-1">
                    <Typography
                      color={canViewAssessment && "secondary"}
                      variant="font_12"
                      className={canViewAssessment && "cursor-pointer"}
                    >
                      {canViewAssessment ? (
                        <BootstrapTooltip title={"Show Assessment"}>
                          <Link
                            to={
                              moduleId
                                ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}`
                                : `/bootcamp/${bootcampId}/assessment/${assessmentId}`
                            }
                          >
                            <u>{title}</u>
                          </Link>
                        </BootstrapTooltip>
                      ) : (
                        title
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">{type}</Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {dayjs(startDate)?.format("DD/MM/YYYY hh:mm A")}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {dayjs(endDate)?.format("DD/MM/YYYY hh:mm A")}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {durationInMinutes ? `${durationInMinutes} mins` : "-"}
                    </Typography>
                  </TableCell>
                  {!viaModule && (
                    <TableCell align="left" className="padding-left-1">
                      <BootstrapTooltip title="View Module">
                        <Typography variant="font_12" color="secondary">
                          <Link
                            to={`/bootcamp/${bootcampId}/module/${module?.id}`}
                          >
                            <u>{module?.name}</u>
                          </Link>
                        </Typography>
                      </BootstrapTooltip>
                    </TableCell>
                  )}
                  <TableCell
                    className="padding-left-1"
                    sx={{ minWidth: "6rem" }}
                  >
                    <Chip
                      className="same-size-chip"
                      label={status?.name}
                      variant={status?.theme}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="padding-left-1 padding-right-2"
                  >
                    <Box className="action-flex">
                      {canViewToggleActive && (
                        <BootstrapTooltip
                          title={
                            !canToggleActive
                              ? toggleActiveMessage
                              : active
                              ? "Inactivate Assessment"
                              : "Activate Assessment"
                          }
                        >
                          <Box>
                            <Switch
                              className="margin-right-2"
                              disabled={!canToggleActive}
                              checked={active || false}
                              size="small"
                              onChange={(event) =>
                                handleActivateInactivateAssessment(
                                  event,
                                  assessmentId,
                                  active
                                )
                              }
                              sx={{
                                margin: "0",
                                "& .MuiSwitch-thumb": {
                                  width: 10,
                                  height: 10,
                                },
                              }}
                            />
                          </Box>
                        </BootstrapTooltip>
                      )}
                      {canViewDeleteAssessment && (
                        <IconButtons
                          tooltip="Delete Assessment"
                          width={font_13_icon}
                          height={font_13_icon}
                          image={delete_active_icon}
                          handleClick={() =>
                            handleDeleteAssessment(assessmentId)
                          }
                          classList="margin-right-1"
                          disabled={!canDeleteAssessment}
                          disabledImage={delete_disabled_icon}
                          disabledTooltip={
                            !canDeleteAssessment
                              ? deleteAssessmentMessage
                              : null
                          }
                        />
                      )}
                      {canViewEditAssessment && (
                        <IconButtons
                          tooltip="Edit Assessment"
                          width={font_13_icon}
                          height={font_13_icon}
                          image={edit_active_icon}
                          handleClick={(event) =>
                            handleEditAssessment(event, assessment)
                          }
                          classList="margin-right-1"
                          disabled={!canEditAssessment}
                          disabledImage={edit_disabled_icon}
                          disabledTooltip={
                            !canEditAssessment ? editAssessmentMessage : null
                          }
                        />
                      )}
                      {canViewListEvaluations && (
                        <Link
                          to={
                            canListEvaluations
                              ? moduleId
                                ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}/evaluations`
                                : `/bootcamp/${bootcampId}/assessment/${assessmentId}/evaluations`
                              : undefined
                          }
                          style={{
                            lineHeight: 0,
                            cursor: "default",
                          }}
                        >
                          <IconButtons
                            width={font_15}
                            height={font_15}
                            image={list_eval_icon}
                            tooltip="List Evaluation"
                            disabled={!canListEvaluations}
                            disabledImage={list_eval_disabled_icon}
                            disabledTooltip={
                              !canListEvaluations
                                ? listEvaluationsMessage
                                : null
                            }
                          />
                        </Link>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">Total {totalResult} Records</Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default AssessmentListingTable;

import ApiService from "../utils/api-service";
import { authBasePath } from "../constants/constants";

export const validateAuthToken = () => {
  return ApiService({
    method: "GET",
    url: `${authBasePath}/validateToken`,
  });
};

export const getLoggedInUser = () => {
  return ApiService({
    method: "GET",
    url: `${authBasePath}/userInfo`,
  });
};
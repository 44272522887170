import React, { useEffect, useState } from "react";
import "./assessmentListing.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearUserData } from "../../store/users";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import AssessmentListingTable from "../../components/AssessmentListingTable/AssessmentListingTable";
import { useNavigate, useParams } from "react-router-dom";
import {
  activateInactivateAssessment,
  deleteAssessment,
  getBootcampForAssessment,
  getModuleForAssessment,
  setAssessmentListingData,
} from "../../store/assessmentListing";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import Constants, { HelpTexts } from "../../constants/constants";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

const { COMPLETED } = Constants;

const AssessmentListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assessmentData = useSelector((state) => state?.assessmentListing);
  const activateInactivateAssessmentLoader =
    assessmentData?.activateInactivateAssessmentLoader;
  const assessmentDataLoading = assessmentData?.assessmentListingLoader;
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (value) => {
    setOffset((value - 1) * 20);
    setCurrentPage(value);
  };
  const assessmentList = assessmentData?.assessmentListingData?.data?.results;
  const totalResult = assessmentData?.assessmentListingData?.data?.totalResults;

  const bootcampData = assessmentData?.bootcampData?.data;
  const moduleData = assessmentData?.moduleData?.data;

  const moduleDataLoader = assessmentData?.getModuleLoader;
  const bootcampDataLoader = assessmentData?.bootcampDataLoader;
  /////////////////////////////Handle delete assessment/////////////////////////////////////////
  const handleDeleteAssessment = async (assessmentId) => {
    setDeleteModalOpen(true);
    setAssessmentId(assessmentId);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setAssessmentId(null);
  };

  const handleDelete = async () => {
    await dispatch(deleteAssessment(assessmentId));
    setDeleteModalOpen(false);
    await dispatch(
      setAssessmentListingData({
        bootcampId: bootcampId,
        moduleId: moduleId,
        sortBy,
        sortOrder: orderBy,
        offset,
      })
    );
    setAssessmentId(null);
  };

  //////////////////////////////////////////////////////////////////////
  const [sortBy, setSortBy] = useState("activeFromDate");
  const [orderBy, setOrderBy] = useState("DESC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
  };

  const params = useParams();
  const { moduleId, bootcampId } = params;

  const handleEditAssessment = (e, assessment) => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment-form`
        : assessment?.module
        ? `/bootcamp/${bootcampId}/module/${assessment?.module?.id}/assessment-form`
        : `/bootcamp/${bootcampId}/assessment-form`,
      {
        state: {
          assessmentId: assessment?.id,
        },
      }
    );
  };

  const handleActivateInactivateAssessment = async (
    e,
    assessmentId,
    active
  ) => {
    await dispatch(activateInactivateAssessment({ id: assessmentId, active }));
    await dispatch(
      setAssessmentListingData({
        bootcampId: bootcampId,
        moduleId: moduleId,
        sortBy,
        sortOrder: orderBy,
        offset,
      })
    );
  };

  const handleCreateAssessmentOpen = () => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment-form`
        : `/bootcamp/${bootcampId}/assessment-form`
    );
  };

  ///////////
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!isNaN(+bootcampId)) {
        await dispatch(getBootcampForAssessment({ bootcampId }));
      } else if (isNaN(+bootcampId)) {
        navigate("/error", { replace: true });
        return;
      }
      if (moduleId && !isNaN(+moduleId)) {
        await dispatch(getModuleForAssessment({ moduleId }));
      } else if (moduleId && isNaN(+moduleId)) {
        navigate("/error", { replace: true });
        return;
      }
      await dispatch(
        setAssessmentListingData({
          bootcampId: bootcampId,
          moduleId: moduleId,
          sortBy,
          sortOrder: orderBy,
          // max,
          offset,
          // paginated,
          // type,
        })
      );

      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, sortBy, orderBy, offset]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    return () => {
      dispatch(clearUserData());
    };
  }, []);

  //------------------------------ Breadcrumbs ------------------------------//
  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: moduleData?.name ? moduleData?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${moduleData?.id}` || "/",
        },
        {
          label: "Assessments",
        },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
        },
      ];

  if (
    assessmentDataLoading ||
    isLoading ||
    bootcampDataLoader ||
    moduleDataLoader ||
    activateInactivateAssessmentLoader
  )
    return <Loader />;
  const isBootcampCompleted = bootcampData?.status === COMPLETED;
  const isBootcampSpoc = bootcampData?.permission?.spoc;
  const isModuleCompleted = moduleData?.status === COMPLETED;
  const currentBootcamp = moduleData?.bootcamps?.results.find(
    (it) => it?.id === Number(params?.bootcampId)
  );
  const isCurrentBootcampCompleted = currentBootcamp?.status === COMPLETED;
  const isModuleSpoc = moduleData?.permission?.spoc;
  const bootcampCompletedMsg =
    "Assessment cannot be created as Bootcamp Status is Completed";
  const moduleCompletedMsg =
    "Assessment cannot be created as Module Status is Completed";
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Assessments</Typography>
          {(isBootcampSpoc || isModuleSpoc) && (
            <BootstrapTooltip
              title={
                moduleId
                  ? isCurrentBootcampCompleted
                    ? bootcampCompletedMsg
                    : isModuleCompleted
                    ? moduleCompletedMsg
                    : ""
                  : isBootcampCompleted
                  ? bootcampCompletedMsg
                  : ""
              }
            >
              <Box className="header-right-icons">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCreateAssessmentOpen}
                  className="margin-right-1"
                  disabled={
                    moduleId
                      ? !isModuleSpoc || isModuleCompleted
                      : !isBootcampSpoc || isBootcampCompleted
                  }
                >
                  <Typography variant="outlineBtnLabel">
                    Create Assessment
                  </Typography>
                </Button>
              </Box>
            </BootstrapTooltip>
          )}
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column user-paper-container"
            >
              {!assessmentDataLoading && !assessmentList?.length ? (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Assessments Found"
                  />
                </Grid>
              ) : (
                assessmentList?.length > 0 && (
                  <AssessmentListingTable
                    assessmentList={assessmentList}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    handleSorting={handleSorting}
                    sortBy={sortBy}
                    orderBy={orderBy}
                    viaModule={moduleId}
                    totalResult={totalResult}
                    handleEditAssessment={handleEditAssessment}
                    handleActivateInactivateAssessment={
                      handleActivateInactivateAssessment
                    }
                    handleDeleteAssessment={handleDeleteAssessment}
                  />
                )
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default AssessmentListing;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import {
  addQuestionAssessment,
  editQuestionAssessment,
  getAssessmentQuestionById,
  setAssessmentQuestionsData,
} from "../../store/assessmentQuestions";
import { useParams } from "react-router-dom";
import { calculateFilterCount } from "../../utils/utils";

const compareFormFields = (initial, current) => {
  const fieldsToCheck = ["title", "description", "type", "difficultyLevel"];

  return fieldsToCheck.some((field) => {
    if (field === "type" || field === "difficultyLevel") {
      return initial[field]?.key !== current[field]?.key;
    }
    return initial[field].trim() !== current[field].trim();
  });
};

const AddAssessmentQuestion = ({
  isEditQuestion,
  setIsEditQuestion,
  addQuestionOpen,
  setAddQuestionOpen,
  question,
  setQuestion,
  isCloneQuestion,
  setIsCloneQuestion,
  initialFilterValues,
  setFilterAssessment,
  setFilterCount,
  setFilterActivated,
  tilesCount,
  setIsLoading,
  setNewData,
  setOffset,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const questionTypes = useSelector(
    (state) => state?.utility?.constants?.questionTypes
  );
  const difficultyLevels = useSelector(
    (state) => state?.utility?.constants?.difficultyLevels
  );
  const assessmentQuestions = useSelector(
    (state) => state?.assessmentQuestions
  );

  const initialFormState = {
    title: "",
    description: "",
    type: null,
    difficultyLevel: null,
  };
  const initialErrorState = {
    title: "",
    description: "",
    type: null,
    difficultyLevel: null,
  };

  const [formDetails, setFormDetails] = useState(initialFormState);
  const [lengthError, setlengthError] = useState(false);
  const [errors, setErrors] = useState(initialErrorState);
  const [specialCharCheck, setSpecialCharCheck] = useState(false);
  const [apiError, setApiError] = useState(false);

  const getClonedTitle = (title) => {
    return `Clone of ${title}`;
  };

  useEffect(() => {
    if (question?.id) {
      const clonedTitle = isCloneQuestion
        ? getClonedTitle(question?.title)
        : question?.title;
      const questionType =
        isEditQuestion || isCloneQuestion
          ? questionTypes.find((type) => type.key === question?.type) || null
          : null;
      const difficultyLevel =
        isEditQuestion || isCloneQuestion
          ? difficultyLevels.find(
              (type) => type?.key === question?.difficultyLevel
            ) || null
          : null;

      const newFormDetails = {
        title: clonedTitle,
        description: question?.description,
        type: questionType,
        difficultyLevel: difficultyLevel,
      };

      setFormDetails(newFormDetails);
      setInitialFormValues({ ...newFormDetails, title: question?.title });
    }
  }, [question?.id]);
  useEffect(() => {
    if (addQuestionOpen) {
      resetErrors();
    }
  }, [addQuestionOpen]);

  const resetErrors = () => {
    setErrors(initialErrorState);
    setApiError(false);
    setSpecialCharCheck(false);
    setlengthError(false);
  };
  const handleInputChange = (name, value) => {
    setApiError(false);
    setFormDetails((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };
  const validateForm = () => {
    const { title, difficultyLevel, type } = formDetails;
    const newErrors = {
      title: title ? "" : "Title is required",
      type: type ? "" : "Type is required",
      difficultyLevel: difficultyLevel ? "" : "Difficulty Level is required",
    };
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const handleCloseDialog = () => {
    resetErrors();
    setFormDetails(initialFormState);
    setAddQuestionOpen(false);
    setIsEditQuestion(false);
    setIsCloneQuestion(false);
    setQuestion(null);
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    let response;
    const payload = {
      ...formDetails,
      ...(isEditQuestion ? { questionId: question?.id } : {}),
      ...(isEditQuestion ? {} : { topicId: params.topicId }),
      title: formDetails?.title?.trim(),
      type: formDetails?.type?.key,
      difficultyLevel: formDetails.difficultyLevel?.key,
      description: formDetails?.description?.trim(),
    };
    if (!specialCharCheck) {
      if (!isEditQuestion) {
        response = await dispatch(addQuestionAssessment(payload));
      } else {
        response = await dispatch(editQuestionAssessment(payload));
      }

      if (response?.error?.message === "Rejected") {
        setApiError(true);
      } else {
        if (!isEditQuestion) {
          setIsLoading(true);
          setAddQuestionOpen(false);
          setNewData(true);
          setOffset(0);
          await dispatch(
            setAssessmentQuestionsData({
              topicId: params.topicId,
              title: initialFilterValues?.title.trim(),
              type: initialFilterValues?.type?.map((item) => item?.key),
              difficultyLevel: initialFilterValues?.difficultyLevel?.map(
                (item) => item?.key
              ),
              showActive:
                initialFilterValues.showActive === "showActive"
                  ? true
                  : initialFilterValues.showActive === "showInactive"
                  ? false
                  : null,
              offset: 0,
              max: tilesCount,
            })
          );
          setIsLoading(false);
        } else {
          await dispatch(
            getAssessmentQuestionById({
              assessmentQuestionId: question?.id,
            })
          );
        }
        setFilterAssessment({ ...initialFilterValues });
        setFilterCount(calculateFilterCount(initialFilterValues));
        setFilterActivated(calculateFilterCount(initialFilterValues));
        handleCloseDialog();
      }
    }
  };
  const [initialFormValues, setInitialFormValues] = useState(initialFormState);

  const isUpdateButtonDisabled = !compareFormFields(
    initialFormValues,
    formDetails
  );

  return (
    <>
      <Dialog
        className="modal-drawer-container"
        open={addQuestionOpen}
        onClose={handleCloseDialog}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
            maxWidth: "29.75rem",
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {isCloneQuestion
            ? "Clone Question"
            : isEditQuestion
            ? "Edit Question"
            : "Add Question"}
        </DialogTitle>
        <DialogContent
          className="dialog-content"
          sx={{ marginBottom: "0 !important" }}
        >
          <Box className="width-100">
            <Box sx={{ marginBottom: "0.75rem" }}>
              <TextField
                multiline
                minRows={2}
                label="Title"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                size="small"
                value={formDetails?.title}
                onChange={(e) => {
                  handleInputChange("title", e?.target?.value);
                }}
                required={true}
                error={
                  !!errors.title || apiError || specialCharCheck || lengthError
                }
                helperText={
                  specialCharCheck
                    ? "Cannot contain special characters"
                    : lengthError
                    ? "Maximum 50 characters allowed"
                    : errors?.title
                }
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <TextField
                multiline
                minRows={4}
                label="Description"
                color="secondary"
                fullWidth
                className="filter-inputs"
                variant="outlined"
                size="small"
                margin="dense"
                value={formDetails?.description}
                onChange={(e) =>
                  handleInputChange("description", e?.target?.value)
                }
                error={!!errors.description}
                helperText={errors.description}
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <SimpleAutoComplete
                label="Type"
                placeholder="Select Type"
                options={questionTypes}
                onChange={(event, newValue) => {
                  handleInputChange("type", newValue);
                }}
                getOptionLabel={(option) => option?.value || ""}
                value={formDetails?.type}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.key}
                      className={
                        option?.key === formDetails?.type?.key
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.value}`}</Box>
                      </Box>
                    </li>
                  );
                }}
                required={true}
                error={!!errors?.type || apiError}
                helperText={errors?.type}
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <SimpleAutoComplete
                label="Difficulty Level"
                placeholder="Select Difficulty Level"
                options={difficultyLevels}
                error={!!errors?.difficultyLevel || apiError}
                helperText={errors?.difficultyLevel}
                onChange={(event, newValue) => {
                  handleInputChange("difficultyLevel", newValue);
                }}
                getOptionLabel={(option) => option?.value || ""}
                value={formDetails?.difficultyLevel}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.key}
                      className={
                        option?.key === formDetails?.difficultyLevel?.key
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.value}`}</Box>
                      </Box>
                    </li>
                  );
                }}
                required={true}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
            className="block-button"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={isUpdateButtonDisabled}
          >
            <Typography variant="outlineBtnLabel">
              {isEditQuestion ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAssessmentQuestion;

import React, { useEffect, useState } from "react";
import "./createBulkBatches.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
  Chip,
  Box,
} from "@mui/material";
import { cross_default_icon, upload_active_icon } from "../../constants/icons";
import { font_9 } from "../../utils/utils";
import IconButtons from "../IconButtons/IconButtons";
import Loader from "../Loader/Loader";
import { HelpTexts, TOAST_TYPE } from "../../constants/constants";
import WarningToast from "../MessagePopup/WarningToast";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 2,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 2,
});

const FileItem = styled("div")({
  display: "flex",
  alignItems: "center",
  margin: "1rem 0 0.5rem 0px",
});

const CreateBulkBatches = ({
  open,
  onClose,
  handleChange,
  data,
  handleCreateSubmit,
  batchCreationError,
}) => {
  //////////////////////////////////////////////////////////////////////////////

  const [create, setCreating] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const handleRemoveFile = () => {
    setSelectedFile(null);
    handleChange(null);
  };

  const handleOnClose = async () => {
    setSelectedFile(null);
    setFileError("");
    onClose();
  };

  const handleCreate = async () => {
    if (data != null) {
      setCreating(true);
      await handleCreateSubmit();
      setSelectedFile(null);
      setCreating(false);
    }
  };
  const handleFileChange = (file) => {
    const maxSize = 4 * 1024 * 1024;
    const allowedTypes = ["text/csv"];

    let errorMessage = "";

    if (file !== undefined && !allowedTypes?.includes(file?.type)) {
      errorMessage = `File ${file?.name} is of an unsupported file type.`;
    } else if (file?.size > maxSize) {
      errorMessage = `File ${file?.name} exceed the maximum size.`;
    }

    if (errorMessage) {
      setFileError(errorMessage);
      return;
    }
    if (file !== undefined && file !== null) {
      setFileError("");
      setSelectedFile(file);
      handleChange(file);
    }
  };
  const handleDownload = () => {
    const csvContent = "batchName, attendeeEmail";

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "CreateBulkBatches.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
  };

  return (
    <>
      {create && <Loader />}
      <Dialog
        open={open}
        onClose={handleOnClose}
        className="create-bulk-batches-modal"
      >
        <DialogTitle className="dialog-title">
          <Typography variant="font_16_bold">Create Bulk Batches</Typography>
        </DialogTitle>
        <DialogContent className="resource-modal-dialog-content">
          <>
            <Typography variant="font_12_bold">
              Upload CSV to Create batches
            </Typography>
            <Button
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              className="block-dashed-btn upload-btn"
              fullWidth
              color="lightGrayText"
              component="label"
              variant="outlined"
              startIcon={
                <img
                  alt="upload"
                  height="20px"
                  width="20px"
                  src={upload_active_icon}
                />
              }
            >
              <Box className="file-upload-instruction">
                <Box>
                  <Typography variant="font_12_bold" color="contrastText">
                    Drag and Drop file or{" "}
                  </Typography>

                  <Typography variant="font_12_bold_600" color="secondary">
                    BROWSE
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="font_10">Upto 4mb (csv)</Typography>
                </Box>
              </Box>
              <VisuallyHiddenInput
                onChange={(e) => {
                  handleFileChange(e?.target?.files[0]);
                }}
                type="file"
              />
            </Button>
            <Button
              onClick={handleDownload}
              color="secondary"
              className="download-template-btn"
            >
              <Typography variant="font_12">Download CSV Template</Typography>
            </Button>
            {fileError && (
              <WarningToast
                message={fileError}
                title="Warning!"
                type={TOAST_TYPE.WARNING}
              />
            )}
            {selectedFile && (
              <FileItem>
                <Chip
                  label={selectedFile.name}
                  variant="outlined"
                  size="large"
                  color="secondary"
                  className="input-files-chip"
                  deleteIcon={
                    <IconButtons
                      height={font_9}
                      width={font_9}
                      image={cross_default_icon}
                      handleClick={() => handleRemoveFile()}
                      classList="user-role-cross"
                    />
                  }
                  onDelete={true}
                />
              </FileItem>
            )}
            {batchCreationError && (
              <Typography className="batch-creation-error">
                {batchCreationError}
              </Typography>
            )}
          </>
        </DialogContent>
        <DialogActions className="create-bulk-batches-modal-dialog-action">
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleOnClose}
            className="create-btn"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleCreate}
            variant="contained"
            color="secondary"
            className="create-btn"
            fullWidth
            disabled={create || !data}
          >
            <Typography variant="outlineBtnLabel">Create</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateBulkBatches;

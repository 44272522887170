import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Timer = ({
  timeLimit,
  onTimeUp,
  isStarted,
  startTime,
  isCompleted,
  timeSpent = 0,
}) => {
  const convertIntoHMS = (totalSeconds) => {
    if (isCompleted) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return { hours: hours || 0, minutes: minutes || 0, seconds: seconds || 0 };
  };

  const calculateRemainingTime = () => {
    const remainingSeconds = (timeLimit - timeSpent) * 60;
    return remainingSeconds > 0 ? remainingSeconds : 0;
  };

  const [remainingSeconds, setRemainingSeconds] = useState(
    calculateRemainingTime()
  );
  const [newAssessment, setNewAssessment] = useState(calculateRemainingTime());

  useEffect(() => {
    let countdownInterval;

    if (isStarted) {
      countdownInterval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds <= 1) {
            clearInterval(countdownInterval);
            onTimeUp();
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [isStarted, startTime, timeLimit]);

  const time = convertIntoHMS(!startTime ? newAssessment : remainingSeconds);
  const formatTime = (value) => String(value).padStart(2, "0");

  const TimeBlock = ({ value, label }) => (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box className="time-units">
        <Typography variant="h5" fontWeight="bold">
          {value}
        </Typography>
      </Box>

      <Typography variant="caption">{label}</Typography>
    </Box>
  );

  const Separator = () => (
    <Typography variant="h5" className="seperator">
      :
    </Typography>
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
      borderRadius="8px"
    >
      <TimeBlock value={formatTime(time?.hours)} label="HRS" />
      <Separator />
      <TimeBlock value={formatTime(time?.minutes)} label="MIN" />
      <Separator />
      <TimeBlock value={formatTime(time?.seconds)} label="SEC" />
    </Box>
  );
};

export default Timer;

import React from "react";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  delete_active_icon,
  delete_disabled_icon,
  edit_active_icon,
  edit_disabled_icon,
  listing_disabled_icon,
  listing_icon,
} from "../../constants/icons";
import { Box, Button, Typography } from "@mui/material";
import { font_16, font_17 } from "../../utils/utils";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";

const BootcampModuleViewActionItems = ({
  handleDeleteModalOpen,
  handleOpenEditModule,
  enableEdit,
  enableDelete,
  handleCreateBatchOpen,
  allBootcampAttendee,
  isModuleCompleted,
  isModuleActive,
  isBootcampCompleted,
  handleListAssessments,
  canListAssessmentQuestions,
  handleBulkCreateBatchOpen,
}) => {
  return (
    <>
      {canListAssessmentQuestions && (
        <IconButtons
          classList="margin-right-1"
          width={font_17}
          height={font_16}
          image={listing_icon}
          handleClick={handleListAssessments}
          tooltip="List Assessments"
        />
      )}
      {enableEdit && (
        <IconButtons
          classList="margin-right-1"
          width={font_17}
          height={font_16}
          image={edit_active_icon}
          disabledImage={edit_disabled_icon}
          handleClick={handleOpenEditModule}
          tooltip="Edit Module"
          disabled={isModuleCompleted}
          disabledTooltip={
            isBootcampCompleted
              ? "Bootcamp is completed"
              : isModuleCompleted
              ? "Module is completed"
              : null
          }
        />
      )}
      {enableDelete && (
        <IconButtons
          classList="margin-right-1"
          width={font_17}
          height={font_16}
          image={delete_active_icon}
          handleClick={() => handleDeleteModalOpen("module")}
          tooltip="Delete Module"
          disabledImage={delete_disabled_icon}
          disabled={isModuleCompleted || isModuleActive}
          disabledTooltip={
            isBootcampCompleted
              ? "Bootcamp is completed"
              : isModuleCompleted
              ? "Module is completed"
              : isModuleActive
              ? "Module is active"
              : null
          }
        />
      )}
      {enableEdit && (
        <>
          <BootstrapTooltip
            title={
              isBootcampCompleted
                ? "Bootcamp is completed"
                : isModuleCompleted
                ? "Module is completed"
                : null
            }
          >
            <Box>
              <Button
                variant="contained"
                color="secondary"
                className="header-action-btn margin-right-2"
                onClick={handleBulkCreateBatchOpen}
                disabled={isModuleCompleted}
              >
                <Typography variant="outlineBtnLabel">
                  Create Bulk Batches
                </Typography>
              </Button>
            </Box>
          </BootstrapTooltip>
          <BootstrapTooltip
            title={
              isBootcampCompleted
                ? "Bootcamp is completed"
                : isModuleCompleted
                ? "Module is completed"
                : null
            }
          >
            <Box>
              <Button
                variant="contained"
                color="secondary"
                className="header-action-btn margin-right-2"
                onClick={handleCreateBatchOpen}
                disabled={isModuleCompleted}
              >
                <Typography variant="outlineBtnLabel">
                  Create Batches
                </Typography>
              </Button>
            </Box>
          </BootstrapTooltip>
        </>
      )}
    </>
  );
};

export default BootcampModuleViewActionItems;

import { apiBasePath } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getBatchesListApi(data) {
  const { moduleId } = data;

  let apiUrl = `${apiBasePath}/moduleBatches`;

  if (moduleId !== undefined && moduleId !== null) {
    apiUrl += `?moduleId=${moduleId}&paginated=false`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function sendEmailApi(data) {
  let apiUrl = `${apiBasePath}/session/sendEmail`;

  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

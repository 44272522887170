import {
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { getDate } from "../../utils/utils";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import { useSelector } from "react-redux";
import TablePagination from "../../components/TablePagination/TablePagination";
import AssessmentQuestionOptions from "./AssessmentQuestionOptions";

const ViewAssessmentTable = ({
  assessmentType,
  assessmentQuestionsData,
  currentPage,
  handlePageChange,
  totalPages,
}) => {
  const questionTypes = useSelector(
    (state) => state?.utility?.constants?.questionTypes
  );
  const difficultyLevels = useSelector(
    (state) => state?.utility?.constants?.difficultyLevels
  );

  const [questionId, setQuestionId] = useState(null);
  const [associateQuestionsModalOpen, setAssociateQuestionsModalOpen] =
    useState(false);
  const handleOptionView = async (id) => {
    setAssociateQuestionsModalOpen(true);
    setQuestionId(id);
  };
  return (
    <>
      <TableContainer
        className="table-container"
        sx={{
          marginBottom: "0.6rem",
          overflow: "scroll",
          width: "100%",
          height: "100%",
        }}
      >
        {!assessmentQuestionsData?.results?.length ? (
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="No Questions Available"
          />
        ) : (
          <Table>
            <TableHead className="table-head-container">
              <TableRow className="table-head-row">
                <TableCell sx={{ minWidth: "20rem" }}>
                  <Typography
                    variant="font_13_bold"
                    sx={{ paddingLeft: "1rem" }}
                  >
                    Question
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "10rem" }}>
                  <Typography variant="font_13_bold" className="padding-left-1">
                    Created By
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "7rem" }}>
                  <Typography variant="font_13_bold" className="padding-left-1">
                    Created On
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "22rem" }}>
                  <Typography className="padding-left-1" variant="font_13_bold">
                    Topic
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "8rem" }}>
                  <Typography variant="font_13_bold" className="padding-left-1">
                    Type
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: "8rem" }}>
                  <Typography variant="font_13_bold" className="padding-left-1">
                    Difficulty
                  </Typography>
                </TableCell>
                {assessmentType === "MCQ" && (
                  <TableCell sx={{ minWidth: "5rem" }} align="center">
                    <Typography
                      variant="font_13_bold"
                      sx={{ paddingRight: "1rem" }}
                    >
                      Options
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {assessmentQuestionsData?.results?.length &&
                assessmentQuestionsData?.results?.map((question, index) => {
                  const questionId = question?.id;
                  const title = question?.title;
                  const createdBy = question?.createdBy?.fullName;
                  const createdOn = question?.dateCreated;
                  const topicName = question?.topic.name;
                  const questionType = question?.type;
                  const difficultyLevel = question?.difficultyLevel;

                  return (
                    <TableRow className="table-body-row" key={index}>
                      <TableCell className="padding-left-1">
                        <Typography variant="font_12">{title}</Typography>
                      </TableCell>
                      <TableCell className="padding-left-1">
                        <Typography variant="font_12">{createdBy}</Typography>
                      </TableCell>
                      <TableCell className="padding-left-1">
                        <Typography variant="font_12">
                          {getDate(createdOn)}
                        </Typography>
                      </TableCell>
                      <TableCell className="padding-left-1">
                        <Typography variant="font_12">{topicName}</Typography>
                      </TableCell>
                      <TableCell className="padding-left-1">
                        <Chip
                          className="same-size-type-chip"
                          label={
                            questionTypes?.filter(
                              (level) => level?.key === questionType
                            )[0]?.value
                          }
                          variant="QUEST_TYPE"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="font_12"
                          className="padding-left-1"
                        >
                          <Chip
                            className="same-size-chip"
                            label={
                              difficultyLevels?.filter(
                                (level) => level?.key === difficultyLevel
                              )[0]?.value
                            }
                            variant={
                              difficultyLevels?.filter(
                                (level) => level?.key === difficultyLevel
                              )[0]?.key
                            }
                          />
                        </Typography>
                      </TableCell>
                      {assessmentType === "MCQ" && (
                        <TableCell
                          align="center"
                          sx={{ paddingRight: "1rem !important" }}
                        >
                          <Button
                            onClick={() => handleOptionView(questionId)}
                            TypographyClasses
                            color="secondary"
                            rel="noopener noreferrer"
                            variant="outlined"
                            className="link-btn"
                            sx={{
                              width: "2.9rem",
                              height: "1.6rem",
                              margin: "0 1rem",
                            }}
                          >
                            <Typography
                              variant="font_10_bold_700"
                              sx={{
                                textTransform: "uppercase",
                              }}
                            >
                              View
                            </Typography>
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Grid container className="table-pagination-container">
        <Typography variant="font_16">
          Total {assessmentQuestionsData?.totalResults} Records
        </Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>

      <AssessmentQuestionOptions
        open={associateQuestionsModalOpen}
        assessmentQuestionsDetails={assessmentQuestionsData}
        questionId={questionId}
        setAssociateQuestionsModalOpen={setAssociateQuestionsModalOpen}
      />
    </>
  );
};

export default ViewAssessmentTable;
